<template>
  <ion-page class="page-content-wrapper">
    <ion-content :fullscreen="true">
      <BackDrop
        :colorClear="true"
        class="backdrop"
        :class="[displayBackDrop === true ? 'display' : '']"
        @click="closeOptionMenu()"
      />
      <!-- ペット情報を取得完了したら表示される -->
      <div v-if="completedGetPetsData === true" class="pets-list">
        <!-- ペットのデータが1件以上あるとき -->
        <div v-if="pets.length !== 0">
          <div v-for="(pet, index) in pets" :key="index" class="each-pet">
            <div class="each-pet_info-wrap" @click="goHistory($event, index)">
              <!-- 画像 -->
              <img
                v-if="pet.petProfile.petAvatarURL"
                :src="pet.petProfile.petAvatarURL"
                class="pet_img"
              />
              <div v-else class="pet_img">
                <ion-icon :icon="paw"></ion-icon>
              </div>
              <!-- 情報 -->
              <div class="each-pet_info-text__wrapper">
                <span class="pet-type">{{ pet.petProfile.petType }}</span>
                <span class="pet-name">{{ pet.petProfile.petName }}</span>
              </div>
            </div>
            <!-- 「…」ボタン -->
            <button
              class="option_menu_button"
              @click.self="openOptionMenu($event, index)"
            >
              <ion-icon
                :icon="ellipsisHorizontal"
                @click.self="openOptionMenu($event, index)"
              ></ion-icon>
            </button>
            <!-- オプションメニュー -->
            <!-- 「clickされたPets」のindex番号（clickedOptionMenuNum）と、「自身の」index番号が一致したら.activeを付与 -->
            <ul
              ref="oprionMenuRef"
              class="option_menu_list"
              v-bind:class="[clickedOptionMenuNum === index ? 'active' : '']"
            >
              <li
                class="option_menu_list_item"
                @click="openAddOrEditPetModal(index)"
              >
                編集
              </li>
              <li
                class="option_menu_list_item"
                @click="confirmDeletePet(index)"
              >
                削除
              </li>
              <li class="option_menu_list_item" @click="closeOptionMenu()">
                キャンセル
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- ペットのデータが1件もない時 -->
      <!-- 猫のイラスト -->
      <p v-if="pets.length === 0" class="cat_blue_comment">
        まずはペットを<br />登録してにゃ〜
      </p>
      <CatBlue v-if="pets.length === 0" class="cat_blue" />
    </ion-content>
    <ion-fab
      slot="fixed"
      ref="fab"
      :class="[downFabZindx === true ? 'down-fab-z-index' : '']"
    >
      <ion-fab-button @click="openAddOrEditPetModal(null)">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-page>
</template>

<script>
/* Vue With Ionic */
import { defineComponent } from "vue";
import {
  IonContent,
  IonPage,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  loadingController,
} from "@ionic/vue";
import { add, ellipsisHorizontal, paw } from "ionicons/icons";

/* モーダル */
import UpdatePleaseModal from "@/components/UpdatePleaseModal";
import AddOrEditPetModal from "@/components/AddOrEditPetModal";

/* コンポーネント */
import BackDrop from "@/components/BackDrop";
import CatBlue from "@/../public/assets/images/CatBlue";

/* サービス */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { UserService } from "@/services/user.js";

/* ユーティリティ */
export default defineComponent({
  name: "Pets",
  components: {
    IonContent,
    IonPage,
    IonFab,
    IonFabButton,
    IonIcon,
    BackDrop,
    CatBlue,
  },
  setup() {
    return {
      add,
      ellipsisHorizontal,
      paw,
    };
  },
  data: function() {
    return {
      userService: new UserService(firebase),
      // dbから返ってきたペットたちの情報を入れるための配列
      pets: [],
      completedGetPetsData: false,
      // clickされたPetsのindex番号を入るための箱。（番号が一致したindexに.activeを付与する。）
      clickedOptionMenuNum: null,
      displayBackDrop: false,
      downFabZindx: false,
    };
  },
  /* createdはページが読み込まれたときにまず走る処理 */
  async created() {
    // 最新versionが存在する時に更新を促すダイアログを表示する
    const needOpenUpdateModal = await this.userService.compareVersions();
    if (needOpenUpdateModal === "openUpdateModal") {
      this.openUpdate();
    }

    // 通信時のローディングの表示について
    const notify = await loadingController.create({
      message: "読込中",
    });
    // 実際にここでloadingを表示
    notify.present();
    // ページが読み込まれたときにペットの情報をとってくる
    await this.getPets();
    this.completedGetPetsData = true;
    // 処理が終わったらloadingを消す
    notify.dismiss();
  },
  computed: {
    selectedPetProfile: function() {
      return this.$store.state.selectedPetProfile;
    },
    selectedPetId: function() {
      return this.$store.state.selectedPetId;
    },
  },
  methods: {
    // アップデートしてくださいモーダルを開く
    async openUpdate() {
      let modal = await modalController.create({
        component: UpdatePleaseModal,
        cssClass: "update-please-modal",
      });
      return modal.present();
    },
    async getPets() {
      this.pets = await this.userService.getPetsData();
      // updatedAtの日付が新しい順に並び替え
      this.pets.sort((a, b) => b.petProfile.updatedAt - a.petProfile.updatedAt);
      if (this.pets.length !== 0 && this.selectedPetId === "") {
        await this.$store.commit("setSelectedPetId", this.pets[0].key);
        await this.$store.commit(
          "setSelectedPetProfile",
          this.pets[0].petProfile
        );
      }
    },
    async confirmDeletePet(index) {
      this.closeOptionMenu();
      this.$store.commit("setDeletePetKey", this.pets[index].key);
      this.$store.commit(
        "setDeletePetName",
        this.pets[index].petProfile.petName
      );
      this.$store.commit(
        "setDeletePetAvatarFileName",
        this.pets[index].petImagesList.petAvatarFileName
      );
      this.$store.commit(
        "setDeletePetAvatarUrl",
        this.pets[index].petProfile.petAvatarURL
      );
      this.$store.commit("setDisplayConfirmDeletePetModal", true);
    },
    async openAddOrEditPetModal(index) {
      // サブメニュー「編集」消す
      this.displayBackDrop = false;
      this.clickedOptionMenuNum = null;
      this.downFabZindx = false;

      let modal = null;

      switch (index) {
        // indexがnullのとき：つまりaddのとき
        case null:
          modal = await modalController.create({
            component: AddOrEditPetModal,
            cssClass: "add-pet-modal",
            componentProps: {
              petNameEdit: null,
              petTypeEdit: null,
              petWeightUnit: null,
              petWelcomeEdit: null,
              petBirthEdit: null,
              petDeathEdit: null,
              petAvatarURLEdit: null,
            },
          });
          break;
        // それ以外：つまりEdit（update）のとき
        default:
          this.$store.commit("setSelectedPetId", this.pets[index].key);
          modal = await modalController.create({
            component: AddOrEditPetModal,
            cssClass: "add-pet-modal",
            componentProps: {
              petNameEdit: this.pets[index].petProfile.petName,
              petTypeEdit: this.pets[index].petProfile.petType,
              petWeightUnitEdit: this.pets[index].petProfile.petWeightUnit,
              petWelcomeEdit: this.pets[index].petProfile.petWelcome,
              petBirthEdit: this.pets[index].petProfile.petBirth,
              petDeathEdit: this.pets[index].petProfile.petDeath,
              petAvatarURLEdit: this.pets[index].petProfile.petAvatarURL,
              petAvatarFileName: this.pets[index].petImagesList
                .petAvatarFileName,
            },
          });
          break;
      }
      return modal.present();
    },
    goHistory(event, index) {
      const tempPetProfile = this.pets[index].petProfile;
      this.$store.commit("setSelectedPetProfile", tempPetProfile);
      this.$store.commit("setSelectedPetId", this.pets[index].key);
      this.$router.push({ path: "history" });
    },
    openOptionMenu(event, index) {
      if (this.clickedOptionMenuNum !== index) {
        // オプションメニューを表示する
        this.displayBackDrop = true;
        this.clickedOptionMenuNum = index;
        this.downFabZindx = true;
      } else {
        // 既に表示済みの時は消す
        this.displayBackDrop = false;
        this.clickedOptionMenuNum = null;
        this.downFabZindx = false;
      }
    },
    closeOptionMenu() {
      this.displayBackDrop = false;
      this.clickedOptionMenuNum = null;
      this.downFabZindx = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.pets-list {
  padding: 1rem;
}
.empty__button--add-pet {
  border: solid #fff 4px;
  border-radius: 16px;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  color: rgba(37, 20, 4, 0.6);
}
.empty__button--add-pet .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.empty__button--add-pet .label {
  margin-left: 0.25rem;
  font-weight: 500;
}
.each-pet {
  display: flex;
  width: 100%;
  padding: calc(0.75rem + 1px) calc(0.75rem + 1px);
  justify-content: space-between;
  background: #fff;
  margin-bottom: 1rem;
  font-size: 1rem;
  border-radius: 1rem;
  position: relative;
}
.each-pet_info-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.pet_img {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5px;
  margin-right: 0.75rem;
}
.pet_img ion-icon {
  color: #ffffff;
  width: 24px;
  height: 24px;
}
.each-pet_info-text__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .pet-type {
    font-size: 0.75rem;
    color: rgba(37, 20, 4, 0.6);
    font-weight: 600;
    margin-top: 0.3rem;
  }
  .pet-name {
    font-size: 1.125rem;
    color: rgba(37, 20, 4, 0.87);
    font-weight: 600;
  }
  .pet-type + .pet-name {
    margin-top: 0.2rem;
  }
}
.option_menu_button {
  width: 2.5rem;
  background: none;
  color: rgba(0, 0, 0, 0.38);
}
.option_menu_list {
  &.active {
    display: block;
  }
  display: none;
  position: absolute;
  right: 0.5rem;
  top: 65%;
  z-index: 1100;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  .option_menu_list_item {
    padding: 0.75rem 1rem;
    color: rgba(37, 20, 4, 0.87);
    &:not(:last-child) {
      border-bottom: solid 1px rgba(0, 0, 0, 0.08);
    }
  }
}
.backdrop {
  display: none;
  &.display {
    display: block;
  }
}
.down-fab-z-index {
  z-index: -1;
}
/* 猫の画像 */
.cat_blue {
  width: 80px;
  height: auto;
  position: fixed;
  bottom: 1.2rem;
  right: 5.6rem;
}
.cat_blue_comment {
  font-size: 0.95rem;
  text-align: center;
  height: auto;
  position: fixed;
  bottom: 4.8rem;
  right: 1.1rem;
}
</style>

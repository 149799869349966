import { createStore } from "vuex";
export default createStore({
  //stateオプションで初期値を設定
  state: {
    name: "",
    selectedPetId: "",
    selectedPetProfile: null,
    user: null,
    deletePetKey: "",
    deletePetName: "",
    deleteAvatarFileName: "",
    deletePetAvatarUrl: "",
    displayConfirmDeletePetModal: false,
    deleteLogKey: "",
    deleteLogDate: "",
    deleteLogWeight: "",
    deleteLogUnit: "",
    displayConfirmDeleteLogModal: false,
    reloadMeasurementLogFlag: false,
  },
  mutations: {
    setName(state, _name) {
      state.name = _name;
    },
    setSelectedPetId(state, _selectedPetId) {
      state.selectedPetId = _selectedPetId;
    },
    setSelectedPetProfile(state, _selectedPetProfile) {
      state.selectedPetProfile = _selectedPetProfile;
    },
    setUser(state, _user) {
      state.user = _user;
    },
    setDeletePetKey(state, _deletePetKey) {
      state.deletePetKey = _deletePetKey;
    },
    setDeletePetName(state, _deletePetName) {
      state.deletePetName = _deletePetName;
    },
    setDeletePetAvatarFileName(state, _deletePetAvatarFileName) {
      state.deletePetAvatarFileName = _deletePetAvatarFileName;
    },
    setDeletePetAvatarUrl(state, _deletePetAvatarUrl) {
      state.deletePetAvatarUrl = _deletePetAvatarUrl;
    },
    setDisplayConfirmDeletePetModal(state, _displayConfirmDeletePetModal) {
      state.displayConfirmDeletePetModal = _displayConfirmDeletePetModal;
    },
    setDeleteLogKey(state, _deleteLogKey) {
      state.deleteLogKey = _deleteLogKey;
    },
    setDeleteLogDate(state, _deleteLogDate) {
      state.deleteLogDate = _deleteLogDate;
    },
    setDeleteLogWeight(state, _deleteLogWeight) {
      state.deleteLogWeight = _deleteLogWeight;
    },
    setDeleteLogUnit(state, _deleteLogUnit) {
      state.deleteLogUnit = _deleteLogUnit;
    },
    setDisplayConfirmDeleteLogModal(state, _displayConfirmDeleteLogModal) {
      state.displayConfirmDeleteLogModal = _displayConfirmDeleteLogModal;
    },
    setReloadMeasurementLogFlag(state, _reloadMeasurementLogFlag) {
      state.reloadMeasurementLogFlag = _reloadMeasurementLogFlag;
    },
  },
  actions: {},
  modules: {},
});

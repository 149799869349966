<template>
  <ion-tabs
    v-if="user != '' && url != '/delete-account'"
    @ionTabsWillChange="beforeTabChange"
    @ionTabsDidChange="afterTabChange"
  >
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="pets" href="/pets" selected>
        <ion-icon :icon="paw" class="paw"></ion-icon>
        <ion-label>ペット一覧</ion-label>
        <!-- <ion-badge>6</ion-badge> -->
      </ion-tab-button>

      <ion-tab-button tab="menu" href="/menu">
        <ion-icon :icon="menu"></ion-icon>
        <ion-label>メニュー</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { defineComponent } from "vue";
import {
  IonIcon,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/vue";
import { paw, analyticsOutline, menu } from "ionicons/icons";

export default defineComponent({
  components: { IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs },
  setup() {
    const beforeTabChange = () => {
      // do something before tab change
    };
    const afterTabChange = () => {
      // do something after tab change
    };
    return {
      paw,
      analyticsOutline,
      menu,
      beforeTabChange,
      afterTabChange,
    };
  },
  data: function() {
    return {
      user: "",
      url: this.$route.path,
    };
  },
  created: function() {
    // 現在ログインしているユーザーを取得する
    firebase.auth().onAuthStateChanged((user) => {
      // ユーザが認証済みの場合（userが取得できた場合）
      if (user) {
        this.user = user;
      }
    });
  },
});
</script>
<style scoped>
ion-icon {
  width: 24px;
  height: 24px;
}
ion-icon.paw {
  width: 1.375rem;
  height: 1.375rem;
  margin-bottom: 3px;
}
.tab-selected {
  color: #f26600;
}
</style>

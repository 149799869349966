<template>
  <!-- header -->
  <div class="modal-header">
    <button @click="dismiss" class="white-button">
      <ion-icon :icon="arrowBack"></ion-icon>
      <span class="button-label">戻る</span>
    </button>
  </div>

  <!-- content -->
  <ion-content class="ion-padding menu-pages">
    <h1 class="page-title">アプリ制作に伴うライセンス情報</h1>
    <p>Thanks to the following great libraries.</p>
    <ul>
      <li
        class="license-info"
        v-for="(license, key) in licenses"
        :key="key"
        @click="onLicenseClick(license)"
      >
        <div class="license-info_title">
          <span class="key">{{ key }}</span>
          <span class="publisher-and-license"
            >{{ license.publisher }} ({{ license.licenses }})</span
          >
        </div>
        <div class="license-info_repository">{{ license.repository }}</div>
      </li>
    </ul>
  </ion-content>
</template>

<script>
import { IonContent, IonIcon, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { arrowBack } from "ionicons/icons";

/* サービス */

import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

/* ライセンスファイル */
import licensesJson from "/public/assets/license/license";

export default defineComponent({
  name: "aboutThisApp",
  data: function() {
    return {
      arrowBack,
      licenses: licensesJson,
    };
  },
  async created() {},
  components: {
    IonContent,
    IonIcon,
  },
  methods: {
    /* モーダルを消す */
    async dismiss() {
      return await modalController.dismiss();
    },
    onLicenseClick(license) {
      // ライセンスをタップすると、ブラウザでリポジトリのページ(GitHubなど)を開く(cordova-plugin-inappbrowserをインストールしておく必要あり)
      window.open(license.repository, "_system");
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-header {
  .white-button {
    background: #fff;
    padding: 1rem 1.5rem 0.875rem 1rem;
  }
}
.license-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 0.8rem;
  .license-info_title {
    margin-bottom: 0.2rem;
    .key {
      display: inline-block;
      font-weight: bold;
      margin-right: 0.5rem;
    }
    .publisher-and-license {
      display: inline-block;
    }
  }
  .license-info_repository {
    font-weight: 200;
  }
}
p {
  line-height: 2;
  font-size: 0.9rem;
}
</style>

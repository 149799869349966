<template>
  <ion-page>
    <ion-content>
      <div class="wrap-content">
        <div class="buttons">
          <ion-buttons>
            <button @click="backToMenu()" class="white-button">
              <ion-icon :icon="arrowBack"></ion-icon>
              <span class="button-label">戻る</span>
            </button>
          </ion-buttons>
        </div>
        <p class="">本当にアカウントを<br />削除しますか？（退会）</p>
        <!-- 要再ログイン：credentialとれてないとき -->
        <div v-if="!gotCredential" class="if-you-whant-please-delete-pets-data">
          <p>
            アカウントを削除するには<br />
            "削除する直前に、再度ログイン"をする必要があります。
          </p>
          <p>
            下のボタンから再度ログインしたのち、「退会する」ボタンを押してください。
          </p>
        </div>
        <button
          v-if="!gotCredential"
          class="googleAuth-btn"
          @click="googleAuth"
        >
          <GoogleLogo class="google-icon" />
          再度ログインする
        </button>
        <!-- 直前にログイン済：credentialとれたとき -->
        <div v-if="gotCredential" class="if-you-whant-please-delete-pets-data">
          <p>
            このアプリは、将来、<br />
            「ペットの種類別に統計を出す」機能を追加で開発する可能性があります。
          </p>
          <p>
            （例えば、「ハムスターの季節ごとの体重の増減割合」について、統計を取ったりなど。）
          </p>
          <p>
            <strong>統計値として情報を使用されくない方へ：</strong><br />
            ペットの一覧画面から、全てのペットの情報を削除してから、退会してください。
          </p>
        </div>
        <div v-if="gotCredential" class="if-you-whant-please-delete-pets-data">
          <p>
            注意：<br />
            退会すると、登録していたペットのデータはすべて閲覧できなくなります。
          </p>
        </div>

        <div v-if="gotCredential" class="how-to-delete-pets-data">
          <p>
            <small>（誤操作での退会を防ぐため）</small><br />
            <strong>『退会する』</strong>と入力して<br />
            退会ボタンを押してください
          </p>
          <ion-input
            placeholder="退会する"
            :value="confirmDeleteAcountText"
            @input="confirmDeleteAcountText = $event.target.value"
            class="input-txt input-delete-account"
          ></ion-input>
        </div>
        <div v-if="gotCredential" class="buttons">
          <ion-buttons>
            <button class="white-button" @click="backToMenu()">
              <span class="button-label">キャンセル</span>
            </button>
            <button
              v-if="!confirmDeleteAcount"
              class="disabled"
              @click="test()"
            >
              退会
            </button>

            <button
              v-if="confirmDeleteAcount"
              class="primary"
              @click="doDeleteAccount()"
            >
              退会
            </button>
          </ion-buttons>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
/**
 * 一旦、ログイン方法をredirectにするのでコメントアウト
 * import { Plugins } from "@capacitor/core";
 * import { cfaSignIn } from "capacitor-firebase-auth";
 * const { Storage } = Plugins;
 */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { UserService } from "@/services/user.js";
import { defineComponent } from "vue";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonInput,
  loadingController,
  IonButtons,
} from "@ionic/vue";
import { arrowBack } from "ionicons/icons";

import GoogleLogo from "@/../public/assets/images/GoogleLogo";

export default defineComponent({
  name: "Welcome",
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonInput,
    GoogleLogo,
    IonButtons,
  },
  data: function() {
    return {
      /**
       * firebase
       */
      userService: new UserService(firebase),
      /**
       * icons
       */
      arrowBack,
      /**
       * flag
       */
      gotCredential: false,
      /**
       * "退会する"入力エリアのテキスト
       */
      confirmDeleteAcountText: "",
    };
  },
  async created() {
    /**
     * 最新versionが存在する時に更新を促すダイアログを表示する
     */
    const needOpenUpdateModal = await this.userService.compareVersions();
    if (needOpenUpdateModal === "openUpdateModal") {
      this.openUpdate();
    }
    /**
     * 現在ログインしているユーザーを取得する
     */
    firebase.auth().onAuthStateChanged((user) => {
      /**
       * ユーザが認証済みの場合（userが取得できた場合）
       */
      if (user) {
        firebase
          .auth()
          .getRedirectResult()
          .then((result) => {
            if (result.credential) {
              /**
               * リダイレクトログイン成功後のみ、直後に「result.credential」の情報が取れる。
               */
              // console.log(
              //   "★★★★★★★★★★★★★★result.credential:",
              //   result.credential
              // );
              this.gotCredential = true;
            }
          });
      }
    });
  },
  computed: {
    /**
     * 退会するボタンを
     * true ... 押下可能
     * false ... disable
     */
    confirmDeleteAcount: function() {
      if (this.confirmDeleteAcountText === "退会する") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    /**
     * googleログイン認証：
     * deploy後、認証が失敗していたのは、「独自ドメイン」をログイン時に許可する設定をしていなかったから。
     * 以下の認証のソースコード自体には問題なし。
     * 対策１）
     * https://console.firebase.google.com/project/pet-weight-chart/authentication/providers
     * から、「承認済みドメイン」にも『pet-weight-chart.com』を追加した。
     * 対策２）
     * main.js 内の configを authDomain: "pet-weight-chart.com" に変更
     * 対策３）
     * https://console.cloud.google.com/apis/credentials?project=pet-weight-chart
     * から、「認証情報 > OAuth 2.0 クライアント ID」内で、
     * 「Web client  (auto created by Google Service)」の
     * 承認済みのリダイレクト URIに『https://pet-weight-chart.com/__/auth/handler』を、追加した。
     * ついでに「承認済みの JavaScript 生成元」にも独自ドメインを追加した。
     * また、「0Auth 同意画面 > アプリ登録の編集」画面から、「承認済みドメイン」に独自ドメインを追加した。
     */
    async googleAuth() {
      const loading = await loadingController.create({
        message: "Please wait...",
      });
      await loading.present();
      /**
       * サインインの方法をpopupからredirectにする必要があり、変更した。
       * Error: Unable to establish a connection with the popup. It may have been blocked by the browser.
       * SPでログイン用のpopupが表示されず、ログインできなかった。
       */
      const provider = await new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithRedirect(provider);
    },
    /**
     * menuページに戻った際に
     * ページをリロードしてTabを表示する必要があるので
     * パラメータを渡して menu ページに router を介して値を props として渡す
     */
    backToMenu() {
      this.$router.push({
        name: "Menu",
        params: { paramsFromDeleteAccountPage: true },
      });
    },
    doDeleteAccount() {
      // console.log("doDeleteAccount");
      const tempRouter = this.$router;
      /**
       * ユーザー削除処理
       * console.log("ログイン中のuser:", user);
       */
      const user = firebase.auth().currentUser;
      user
        .delete()
        .then(function() {
          /**
           * ユーザー削除成功時
           */
          tempRouter.push({
            name: "Welcome",
          });
        })
        .catch(function(error) {
          /**
           * エラー発生時
           */
          console.log("ユーザー削除エラー：", error);
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.wrap-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
}
.googleAuth-btn {
  margin: 0 auto;
  display: block;
  width: 75%;
  max-width: 20rem;
  text-align: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(37, 20, 4, 0.87);
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 16px;
}
.google-icon {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
}
/* 入力エリア */
ion-input {
  background: #fff;
  border-radius: 1rem;
  font-weight: normal;
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  text-align: left;
}
/* ボタン */
ion-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.buttons {
  display: flex;
  margin-top: 1.5rem;
  width: 100%;
  &:first-child {
    margin-top: 0;
  }
}
button {
  font-size: 16px;
  font-size: 1rem;
  padding: 16px 24px 14px;
  padding: 1rem 1.5rem 0.875rem;
  border-radius: 16px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  background: none;
  line-height: 1;
  color: rgba(180, 114, 51, 0.87);
  font-weight: bolder;
  &.primary {
    background: linear-gradient(90deg, #f26600 0%, #f8a505 100%);
    color: #fff;
  }
  &.disabled {
    background: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.4);
  }
  .button-label {
    margin-left: 8px;
  }
  &.white-button {
    font-size: 16px;
    font-size: 1rem;
    padding: 16px 24px 14px;
    padding: 1rem 1.5rem 0.875rem;
    border-radius: 16px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    line-height: 1;
    color: rgba(180, 114, 51, 0.87);
    font-weight: bolder;
    background: #fff;
    padding: 1rem 1.5rem 0.875rem 1rem;
    ion-icon + .button-label {
      margin-left: 8px;
    }
  }
}
</style>
<style scoped>
ion-input >>> input {
  padding-inline-start: var(--padding-start) !important;
  padding: 0.8rem 1.25rem !important;
  padding-inline-end: 0;
}
</style>

export default {
    getNowYMD() {
        var dt = new Date();
        return `${dt.getFullYear()}-${("00" + (dt.getMonth() + 1)).slice(-2)}-${(
      "00" + dt.getDate()
    ).slice(-2)}`;
    },
    getNowTime(add = 1) {
        var dt = new Date();
        return `${("00" + dt.getHours()).slice(-2)}:${(
      "00" +
      (dt.getMinutes() + add)
    ).slice(-2)}`;
    },
}
// もしSafariでバグる時は、下記のようにして変換する。
// RangeError: Invalid Date
// new Date('2011-04-12'.replace(/-/g, "/")));

/**
 * router ルーティングの設定
 */
import { createRouter, createWebHistory } from "@ionic/vue-router";
import Pets from "@/views/pets";
import History from "@/views/history";
import Menu from "@/views/menu";
import Welcome from "@/views/welcome";
import DeleteAccount from "@/views/delete-account";
import TermsOfUse from "@/views/terms-of-use";
import PrivacyPolicy from "@/views/privacy-policy";
import SpecifiedCommercialTransactionsAct from "@/views/specified-commercial-transactions-act";
import AddOrEditPetModal from "@/components/AddOrEditPetModal";
import NotFound from "@/components/NotFound";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/",
    redirect: "/pets",
    meta: {
      requiresAuth: true, // 「認証が必要」ということを示すフラグ
    },
  },
  // ユーザー削除（退会）ページ
  {
    path: "/delete-account",
    name: "Delete Account",
    component: DeleteAccount,
    meta: {
      requiresAuth: true, // 「認証が必要」ということを示すフラグ
    },
  },
  {
    path: "/terms-of-use",
    name: "terms of use",
    component: TermsOfUse,
  },
  {
    path: "/privacy-policy",
    name: "privacy policy",
    component: PrivacyPolicy,
  },
  {
    path: "/specified-commercial-transactions-act",
    name: "specified commercial transactions act",
    component: SpecifiedCommercialTransactionsAct,
  },
  {
    path: "/pets",
    name: "Pets",
    component: Pets,
    meta: {
      requiresAuth: true, // 「認証が必要」ということを示すフラグ
    },
  },
  {
    path: "/history",
    name: "History",
    component: History,
    meta: {
      requiresAuth: true, // 「認証が必要」ということを示すフラグ
    },
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
    props: true, // propsを有効にする（delete-accoutページからの遷移を判定する値を受け取り、リロードするため）
    meta: {
      requiresAuth: true, // 「認証が必要」ということを示すフラグ
    },
  },
  {
    path: "/addpet",
    name: "AddOrEditPetModal",
    component: AddOrEditPetModal,
    meta: {
      requiresAuth: true, // 「認証が必要」ということを示すフラグ
    },
  },
  // 参考：https://router.vuejs.org/guide/migration/#removed-star-or-catch-all-routes
  // pathMatch is the name of the param, e.g., going to /not/found yields
  // { params: { pathMatch: ['not', 'found'] }}
  // this is thanks to the last *, meaning repeated params and it is necessary if you
  // plan on directly navigating to the not-found route using its name
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  { path: "/:pathMatch(.*)", name: "bad-not-found", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// ルーティングする時のチェック
router.beforeEach((to, from, next) => {
  // リダイレクト先が設定されていれば取得しておく
  let redirect = null;
  if (to.query.redirect) {
    redirect = to.query.redirect;
  }
  // 現在ログインしているユーザーを取得する
  firebase.auth().onAuthStateChanged((user) => {
    // ユーザが認証済みの場合（userが取得できた場合）
    if (user) {
      // リダイレクトが設定されていればリダイレクト先に
      if (redirect == "/menu") {
        next("/");
        return;
      }
      if (redirect != null) {
        next(redirect);
        return;
      }
      // Welcomeページに入る時、ログインされているならHome画面に行く
      else if (to.name == "Welcome") {
        next({
          name: "Pets",
        });
      }
      // 認証済みならどこでも行ってもOK
      else {
        next();
      }
    }

    // ユーザが認証されていない場合
    else {
      // 認証が必要かチェックし、必要であればWelcomeページに強制遷移
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        next({
          name: "Welcome",
          query: {
            redirect: to.fullPath,
          },
        });
      }
      // 認証が必要ない場合はそのままでOK
      else {
        next();
      }
    }
  });
});

export default router;

<template>
  <!-- header -->
  <div class="modal-header">
    <button @click="backToHome" class="white-button">
      <ion-icon :icon="arrowBack"></ion-icon>
      <span class="button-label">戻る</span>
    </button>
  </div>
  <ion-content class="ion-padding menu-pages">
    <PrivacyPolicyContent />
  </ion-content>
</template>

<script>
import { defineComponent } from "vue";
import PrivacyPolicyContent from "@/components/PrivacyPolicyContent";
import { arrowBack } from "ionicons/icons";

export default defineComponent({
  name: "PrivacyPolicyPage",
  data: function() {
    return {
      arrowBack,
    };
  },
  async created() {},
  components: {
    PrivacyPolicyContent,
  },
  methods: {
    /* ホームへリダイレクト */
    backToHome() {
      this.$router.push("/");
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-header {
  .white-button {
    background: #fff;
    padding: 1rem 1.5rem 0.875rem 1rem;
  }
}
</style>

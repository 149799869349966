<template>
  <div class="backdrop" :class="[colorClear === true ? 'clear' : '']"></div>
</template>

<script>
export default {
  props: {
    colorClear: {
      type: Boolean, // Boolean型に限定
    },
  },
};
</script>
<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 200vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  &.clear {
    background-color: rgba(0, 0, 0, 0);
  }
}
</style>

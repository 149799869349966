<template>
  <ion-app>
    <ion-router-outlet />
    <ConfirmDeletePetModal v-if="displayConfirmDeletePetModal === true" />
    <ConfirmDeleteLogModal v-if="displayConfirmDeleteLogModal === true" />
    <Tabs />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";

import Tabs from "@/components/Tabs";
import ConfirmDeletePetModal from "@/components/ConfirmDeletePetModal";
import ConfirmDeleteLogModal from "@/components/ConfirmDeleteLogModal";

export default defineComponent({
  name: "App",
  data: function() {
    return {
      petName: "",
    };
  },
  components: {
    IonApp,
    IonRouterOutlet,
    Tabs,
    ConfirmDeletePetModal,
    ConfirmDeleteLogModal,
  },
  computed: {
    displayConfirmDeletePetModal: function() {
      return this.$store.state.displayConfirmDeletePetModal;
    },
    displayConfirmDeleteLogModal: function() {
      return this.$store.state.displayConfirmDeleteLogModal;
    },
  },
  methods: {},
});
</script>

<template>
  <span></span>
</template>

<script>
import { defineComponent } from "vue";
import "firebase/auth";
import "firebase/firestore";

export default defineComponent({
  name: "NotFound",
  async created() {
    // console.log("404だよ");
    await this.$router.push({ path: "/" });
  },
});
</script>

<style scoped></style>

<template>
  <!-- header -->
  <div class="modal-header">
    <button @click="dismiss" class="white-button">
      <ion-icon :icon="arrowBack"></ion-icon>
      <span class="button-label">戻る</span>
    </button>
  </div>

  <!-- content -->
  <ion-content class="ion-padding menu-pages">
    <PrivacyPolicyContent />
  </ion-content>
</template>

<script>
import { IonContent, IonIcon, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { arrowBack } from "ionicons/icons";

import PrivacyPolicyContent from "@/components/PrivacyPolicyContent";

/* サービス */

import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

export default defineComponent({
  name: "PrivacyPolicyModal",
  data: function() {
    return {
      arrowBack,
    };
  },
  async created() {},
  components: {
    IonContent,
    IonIcon,
    PrivacyPolicyContent,
  },
  methods: {
    /* モーダルを消す */
    async dismiss() {
      return await modalController.dismiss();
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-header {
  .white-button {
    background: #fff;
    padding: 1rem 1.5rem 0.875rem 1rem;
  }
}
</style>

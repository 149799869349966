<template>
  <!-- header -->
  <div class="modal-header">
    <button @click="dismiss" class="white-button">
      <ion-icon :icon="arrowBack"></ion-icon>
      <span class="button-label">戻る</span>
    </button>

    <button v-if="addOrEdit === 'add' && !weight" class="disabled">登録</button>

    <button
      v-if="addOrEdit === 'add' && weight"
      class="primary"
      @click="addData"
    >
      登録
    </button>

    <button v-if="addOrEdit === 'edit' && !weight" class="disabled">
      更新
    </button>

    <button
      v-if="addOrEdit === 'edit' && weight"
      class="primary"
      @click="updateData"
    >
      更新
    </button>
  </div>

  <!-- content -->
  <ion-content class="ion-padding">
    <div class="pet_info-wrap">
      <!-- 画像 -->
      <img
        v-if="selectedPetProfile.petAvatarURL"
        :src="selectedPetProfile.petAvatarURL"
        class="pet_img"
      />
      <div v-else class="pet_img">
        <ion-icon :icon="paw"></ion-icon>
      </div>
      <!-- 情報 -->
      <div class="each-pet_info-text__wrapper">
        <span class="pet-type">{{ selectedPetProfile.petType }}</span>
        <span class="pet-name">{{ selectedPetProfile.petName }}</span>
      </div>
    </div>
    <!-- ペットの画像 -->
    <!-- <div class="pet_img_wrapper">
      <img v-if="photoSrc !== ''" :src="photoSrc" class="pet_img" @click="getPhoto()" />
      <div v-else class="pet_img" @click="getPhoto()">
        <ion-icon :icon="paw"></ion-icon>
      </div>

      <button v-if="photoSrc" @click="clearPhoto()" class="button_normal__outline">
        <span class="button_with_icon__inner">
          <ion-icon :icon="closeOutline"></ion-icon>
          <span>削除</span>
        </span>
      </button>
      <button v-else @click="getPhoto()" class="button_normal__outline">
        <span class="button_with_icon__inner">
          <ion-icon :icon="image"></ion-icon>
          <span>写真を登録</span>
        </span>
      </button>
    </div>-->
    <!-- 名前など -->
    <div class="input-wrapper">
      <div class="input-date-wrapper">
        <!-- <ion-item class="input-date-area">
        </ion-item>-->
        <div class="date-picker-wrap">
          <ion-datetime
            class="ion-datetime"
            :class="{ today: isToday }"
            placeholder="例）2020/01/01"
            min="1900-01-01"
            max="2030-12-31"
            :value="day"
            @ionChange="day = $event.target.value"
            day-short-names="[日, 月, 火, 水, 木, 金, 土]"
            display-format="YYYY/MM/DD (DDD)"
            month-short-names="1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12"
          ></ion-datetime>
          <div class="today-label" :class="{ 'not-today': !isToday }">今日</div>
        </div>
        <div>の体重は</div>
      </div>
      <div class="input-weight-wrapper">
        <div class="input-weight-area" v-bind:style="WeightFormStyle">
          <input
            placeholder="--.-"
            v-model.number="weight"
            class="input-weight"
            v-bind:style="WeightFormStyle"
            type="number"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          />
        </div>
        <p class="input-weight-unit">{{ selectedPetProfile.petWeightUnit }}</p>
      </div>
      <textarea
        v-model="memo"
        placeholder="一言メモ"
        class="input-memo"
      ></textarea>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, IonIcon, modalController, IonDatetime } from "@ionic/vue";
import { defineComponent } from "vue";
import { arrowBack, paw, image } from "ionicons/icons";

/* サービス */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { UserService } from "@/services/user.js";
import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

/* 画像アップロード・カメラ起動のための */
// import { Plugins, CameraResultType } from "@capacitor/core";
// const { Camera } = Plugins;

/* ユーティリティ */
import Utils from "@/services/utils.js";

export default defineComponent({
  name: "RecordWeightModal",
  props: {
    logKeyEdit: { type: String, default: "" },
    dateEdit: { type: Object, default: null },
    weightEdit: { type: Number, default: null },
    unitEdit: { type: String, default: "" },
    memoEdit: { type: String, default: "" },
  },
  data: function() {
    return {
      addOrEdit: "add",
      arrowBack,
      paw,
      image,
      userService: new UserService(firebase),
      today: Utils.getNowYMD(),
      day: Utils.getNowYMD(),
      tempDate: null,
      time: Utils.getNowTime(1),
      weight: null,
      unit: "g",
      memo: "",
      weightLength: 0,
      WeightFormStyle: `width: 7rem`,
    };
  },
  async created() {
    this.dayjs = dayjs;
    this.addOrEdit = "add";
    if (this.dateEdit !== null) {
      this.addOrEdit = "edit";
      this.weight = this.weightEdit;
      this.unit = this.unitEdit;
      this.memo = this.memoEdit;
      this.day = this.formatTimestamp(this.dateEdit.toDate());
      this.tempDate = this.formatTimestamp2(this.dateEdit.toDate());
      // console.log("this.tempDate", this.tempDate);
    }
  },
  components: {
    IonContent,
    // IonHeader,
    // IonToolbar,
    IonIcon,
    IonDatetime,
    // IonButton,
    // IonButtons
  },
  watch: {
    weight: function() {
      this.weightLength = String(this.weight).length;
      this.WeightFormStyle = `width: ${this.weightLength * 3 + 0.2}rem`;
      if (this.weightLength === 0) {
        this.WeightFormStyle = `width: 7rem`;
      }
      // 数字入力エリア幅用
      // console.log("this.weightLength", this.weightLength);
    },
    day: function() {
      this.tempDate = this.day;
    },
  },
  computed: {
    selectedPetProfile: function() {
      return this.$store.state.selectedPetProfile;
    },
    selectedPetId: function() {
      return this.$store.state.selectedPetId;
    },
    reloadMeasurementLogFlag: function() {
      return this.$store.state.reloadMeasurementLogFlag;
    },
    isToday: function() {
      // this.today: 「YYYY-MM-DD」
      if (this.day.slice(0, 10) === this.today) {
        // 日付が変更された時
        // this.day「YYYY-MM-DDT00:00:00+09:00」形式
        // つまりsliceして「YYYY-MM-DD」になる
        // this.day が変更されるタイミングで
        // tempDate は this.day になる
        return true;
      } else if (this.tempDate === this.today) {
        // 初回読み込み時に「今日」と表示するか判定する用
        // dateEditが設定されていたとき、tempDateに「YYYY-MM-DD」の形で入れておく
        // 初回読み込み時はthis.dayは「YYYY/MM/DD」形式
        return true;
      } else {
        // 一致しない時
        return false;
      }
    },
  },
  methods: {
    /* モーダルを消す */
    async dismiss() {
      return await modalController.dismiss();
    },
    /* データをサーバへ送るために、logに詰めて渡す */
    async addData() {
      /**
       * 体重のログの追加時に、
       * userのid名のドキュメントが存在しなければ
       * users/{userId}/ を作って、そこに
       * userId、createdAt、updatedAt を作る。
       * 既に存在していれば、updatedAt を更新する
       * 1匹目の登録時にドキュメントを作成するので、
       * 通常は既に存在するはず。
       */
      await this.userService.addOrUpdateUserDocument();

      const targetTime = `${this.day.split("T")[0]} ${this.time}`;

      const log = {
        date: new Date(targetTime.replace(/-/g, "/")),
        weight: this.weight,
        memo: this.memo,
        unit: this.unit,
      };
      // 追加が成功したらtrueが帰ってくる
      const completeAddLog = await this.userService.addMeasurementLog(
        this.selectedPetId,
        log
      );
      if (completeAddLog === true) {
        this.$store.commit("setReloadMeasurementLogFlag", true);
      }
      await modalController.dismiss();
    },
    /* データの更新 */
    async updateData() {
      /**
       * 体重のログの更新時に、
       * userのid名のドキュメントが存在しなければ
       * users/{userId}/ を作って、そこに
       * userId、createdAt、updatedAt を作る。
       * 既に存在していれば、updatedAt を更新する
       * 1匹目の登録時にドキュメントを作成するので、
       * 通常は既に存在するはず。
       */
      await this.userService.addOrUpdateUserDocument();
      const log = {
        date: null, // 下で日付変換して入れる
        weight: this.weight,
        memo: this.memo,
        unit: this.unit,
      };
      const targetTime = `${this.day.split("T")[0]} ${this.time}`;
      log.date = new Date(targetTime.replace(/-/g, "/"));

      await this.userService.updateMeasurementLog(
        this.selectedPetId,
        this.logKeyEdit,
        log
      );
      /*
       * History.vue の reloadMeasurementLogFlag を true にできればよい
       */
      await this.$store.commit("setReloadMeasurementLogFlag", true);
      await modalController.dismiss();
    },
    formatTimestamp(date) {
      return dayjs(date).format("YYYY/MM/DD");
    },
    formatTimestamp2(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
});
</script>

<style lang="scss" scoped>
/* .modal-header {
  display: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding: 1rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  button {
    font-size: 16px;
    font-size: 1rem;
    padding: 16px 24px 14px;
    padding: 1rem 1.5rem 0.875rem;
    border-radius: 16px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    background: none;
    line-height: 1;
    color: rgba(37, 20, 4, 0.87);
    font-weight: bolder;
    &.primary {
      background: linear-gradient(90deg, #f26600 0%, #f8a505 100%);
      color: #fff;
    }
    .button-label {
      margin-left: 8px;
    }
  }
} */
.modal-header {
  .white-button {
    background: #fff;
    padding: 1rem 1.5rem 0.875rem 1rem;
  }
}

.pet_info-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 1.2rem;
}
.each-pet_info-text__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .pet-type {
    font-size: 0.75rem;
    color: rgba(37, 20, 4, 0.6);
    font-weight: 600;
    margin-top: 0.3rem;
  }
  .pet-name {
    font-size: 1.125rem;
    color: rgba(37, 20, 4, 0.87);
    font-weight: 600;
  }
  .pet-type + .pet-name {
    margin-top: 0.2rem;
  }
}

/* ペットの画像 */
.pet_img_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pet_img {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
}
.pet_img ion-icon {
  color: #ffffff;
  width: 24px;
  height: 24px;
}

/* input */
.input-wrapper {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 24px;
  padding-bottom: 64px;
}
.input-wrapper ion-item:not(:last-child) {
  margin-bottom: 20px;
}

/* メモ */
.input-memo {
  background: #fff;
  padding: 12px !important;
  height: 8rem;
  border-radius: 1rem;
  align-items: flex-start;
  border: none;
  width: 100%;
  &:focus {
    outline: none;
  }
}

/* 日付 */
.input-date-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
  ion-item.item-datetime {
    margin-bottom: 0;
  }
}
.date-picker-wrap {
  margin-right: 8px;
  position: relative;
  background: #fff;
  border-radius: 16px;
  .today-label {
    position: absolute;
    top: calc(50% - 0.75rem);
    left: calc(50% - 1rem);
    &.not-today {
      opacity: 0;
    }
  }
}

/* 重さ */
.input-weight-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.input-weight-area {
  width: 7rem;
  overflow: hidden;
}
.input-weight {
  text-align: right;
  width: 7rem;
  font-size: 4rem;
  height: 4rem;
  font-weight: bold;
  padding: 0;
  border: none;
  background: none;
  margin-bottom: 20px;
  &:focus {
    outline: none;
  }
}
.input-weight-unit {
  font-size: 3rem;
  margin: 0 0 0 8px;
  font-weight: bold;
}
</style>
<style scoped>
ion-datetime.ion-datetime {
  --padding-top: 12px;
  --padding-end: 16px;
  --padding-bottom: 12px;
  --padding-start: 16px;
}
ion-datetime.ion-datetime.today {
  opacity: 0;
}
</style>

// 通信処理
export class UserService {
  constructor(_firebase) {
    this.firebase = _firebase;
    this.db = _firebase.firestore();
    this.storage = _firebase.storage();
  }

  /*
   * ユーザー情報を取得する
   */
  getUser() {
    return this.firebase.auth().currentUser;
  }

  /*
   * userIDを取得する
   */
  getUid() {
    return this.firebase.auth().currentUser.uid;
  }

  /*
   * ユーザー情報を登録する
   */
  async addUserInfo() {
    return await this.db
      .collection("users")
      .add(JSON.parse(JSON.stringify(this.firebase.auth().currentUser)));
  }

  /*
   * 時間とUIDを登録する
   */
  // async addNotification(time) {
  //   return await this.db.collection(`users`).add({
  //     time: time,
  //     uid: this.firebase.auth().currentUser.uid,
  //   });
  // }

  /*
   * 通知情報一覧を取得する
   */
  // async getNotification() {
  //   const snapShot = await this.db.collection(`users`).get();
  //   return this.parseCollection(snapShot);
  // }

  /*
   * バージョン情報を取得して、アプリの更新を促すかどうか判断する
   */
  async compareVersions() {
    /**
     * 現在のソースコードのバージョン数
     */
    // TODO: ★アップデートしてdeployする時には必ずここのバージョン情報を更新してリモートのバージョンと同じにすること
    const localVersion = "1";
    /**
     * 最新versionが存在する時に更新を促すダイアログを表示する
     * このページで持ってる（ローカルのソースコードの）バージョンと、
     * 取りに行ったリモートのバージョンが同じかどうか比較する。
     * 違う場合、アプリの更新を促す
     */
    const originVersion = await this.db
      .collection(`version`)
      .doc(`version`)
      .get();
    if (localVersion !== originVersion.data().version) {
      return "openUpdateModal";
    } else {
      return "you don't need update";
    }
  }

  /*
   * コレクションにキーを付与して返す共通メソッド
   */
  parseCollection(snapShot) {
    return snapShot.docs.map((doc) => {
      const tmp = doc.data();
      tmp["key"] = doc.id;
      return tmp;
    });
  }

  /*
   * ペットのプロフィールをCloud Firestoreへ登録する通信処理
   */
  async addPetProfile(petProfile, petImagesList) {
    /* user.jsはvueのインスタンスを引き継いでないので、
    もし元ファイルのthisを呼びたければ
    addPetProfileを呼び出しているところの引数にthisを渡してもってくる。
    例えば、元の引数にthisを入れてctxで渡してあげればよい */
    const user = this.getUser();
    return await this.db
      .collection(`pets`)
      .add({
        petProfile,
        petImagesList,
        users: [`${user.uid}`],
      })
      .then(function(docRef) {
        // idは「docRef.id」でとってこれる
        // addPetProfileを呼び出した元に返してあげる。cosnt などで受け取る。
        // .thenした中のthisのスコープはvueのインスタンスのスコープではなくて、firebaseAppがインスタンスになります
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  }

  async addOrUpdateUserDocument() {
    const user = this.getUser();
    /**
     * 既にユーザーのuser.uidをidにしたドキュメントがある時は
     * 更新日時（updatedAt）をアップデート
     */
    const checkDocmentExistence = await this.db
      .collection(`users`)
      .doc(`${user.uid}`)
      .get();
    if (checkDocmentExistence.data() !== undefined) {
      return await this.db
        .collection(`users`)
        .doc(`${user.uid}`)
        .set(
          {
            updatedAt: this.firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(function() {
          return;
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    } else {
      /**
       * まだユーザーのドキュメントがない時は
       * ドキュメントを作成する
       */
      return await this.db
        .collection(`users`)
        .doc(`${user.uid}`)
        .set({
          id: `${user.uid}`,
          createdAt: this.firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: this.firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function() {
          return;
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    }
  }

  /*
   * Storageに登録したペットのプロフィールアバター画像のURLを、Cloud Firestoreへ登録する通信処理
   */
  async addPetProfileAvatarURL(petId, photoUrl, imgType) {
    const imageFileName = `avatar.${imgType}`;
    return await this.db
      .collection(`pets`)
      .doc(`${petId}`)
      .set(
        {
          petProfile: { petAvatarURL: photoUrl },
          petImagesList: { petAvatarFileName: imageFileName },
        },
        { merge: true }
      )
      .then()
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  }

  /*
   * ペットのプロフィール画像を登録して、URLを取得して返す通信処理
   */
  async addPetProfileAvatarAndGetAvatarURL(addedPetId, tempPhoto, imgType) {
    // const type = tempPhoto.format;
    const storageRef = this.storage.ref();
    const fileRef = storageRef.child(`pets/${addedPetId}/avatar.${imgType}`);

    // base64（ファイルのリサイズ前）
    // const res = await fileRef.putString(tempPhoto.base64String, "base64");
    // return await res.ref.getDownloadURL();

    // blob（ファイルのリサイズ後）
    const res = await fileRef.put(tempPhoto);
    return await res.ref.getDownloadURL();
  }

  /*
   * ペットのプロフィール画像を削除
   */
  async deletePetProfileAvatar(selectedPetId, petAvatarFileName) {
    const storageRef = this.storage.ref();
    /**
     * ペットのAvatar画像を削除
     */
    const fileRef = storageRef.child(
      `pets/${selectedPetId}/${petAvatarFileName}`
    );
    /**
     * Delete the file
     */
    const res = fileRef
      .delete()
      .then(function() {
        /**
         * "delete success!"
         */
        return true;
      })
      .catch(function() {
        /**
         * "delete error"
         */
        return false;
      });
    return await res;
  }

  /*
   * Storageに登録したペットのプロフィールアバター画像のURLを、Cloud Firestoreへ登録する通信処理
   */
  async deletePetProfileAvatarURL(petId) {
    return await this.db
      .collection(`pets`)
      .doc(`${petId}`)
      .set(
        {
          petProfile: { petAvatarURL: "" },
          petImagesList: { petAvatarFileName: "" },
        },
        { merge: true }
      )
      .then()
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  }

  /*
   * ペットの情報を取得する
   */
  async getPetsData() {
    const user = this.getUser();
    // 自分のuser.uidを持っているpetの情報のみフィルタして取得する
    const snapShot = await this.db
      .collection("pets")
      .where("users", "array-contains", `${user.uid}`)
      .get();
    return this.parseCollection(snapShot);
  }

  /*
   *ペットの情報を削除する
   */
  async deletePet(key) {
    let completeDeletePet = false;
    await this.db
      .collection(`pets`)
      .doc(key)
      .delete()
      .then(function() {
        /**
         * `pet ${key} Document successfully deleted!`
         */
        completeDeletePet = true;
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });
    return completeDeletePet;
  }

  /*
   *ペットのプロフィール情報を更新する通信処理
   */
  async updatePetProfile(selectedPetId, petProfile) {
    return await this.db
      .collection(`pets`)
      .doc(`${selectedPetId}`)
      .set(
        {
          petProfile: {
            petName: petProfile.petName,
            petType: petProfile.petType,
            petWeightUnit: petProfile.petWeightUnit,
            petWelcome: petProfile.petWelcome,
            petBirth: petProfile.petBirth,
            petDeath: petProfile.petDeath,
            updatedAt: this.firebase.firestore.FieldValue.serverTimestamp(),
          },
        },
        { merge: true }
      )
      .then()
      .catch(function(error) {
        console.error("Error update document: ", error);
      });
  }

  /*
   * ペットの画像ファイルを全て削除する
   */
  async deletePetImages(petId, petImagesList) {
    const storageRef = this.storage.ref();

    let completeDeletePetImages = false;

    if (petImagesList === undefined) {
      completeDeletePetImages = true;
    } else if (petImagesList !== undefined) {
      if (petImagesList.length > 0) {
        let judgeCompleteDeletePetImages = [];
        for (let i = 0; i < petImagesList.length; i++) {
          // ペットの画像リストを1つずつ入れる
          const deletePetImage = petImagesList[i];

          // 削除するためのパスを作る
          const desertRef = storageRef.child(`pets/${petId}/${deletePetImage}`);

          // 削除する
          await desertRef
            .delete()
            .then(function() {
              // console.log("deleted successfully", deletePetImage);
            })
            .catch(function(error) {
              judgeCompleteDeletePetImages.push("fail");
              console.error(
                `Error deletePetImage: ${petId}/${deletePetImage}`,
                error
              );
            });
        }
        const tempLength = judgeCompleteDeletePetImages.length;
        if (tempLength === 0) {
          completeDeletePetImages = true;
        } else {
          completeDeletePetImages = false;
        }
      }
    }
    return completeDeletePetImages;
  }

  /*
   * ペットの体重記録を取得する
   */
  async getMeasurementLogs(petId) {
    const snapShot = await this.db
      .collection(`pets`)
      .doc(`${petId}`)
      .collection(`measurementLogs`)
      .get();

    const measurementLogs = snapShot.docs.map((doc) => {
      const tmp = doc.data();
      tmp["key"] = doc.id;
      return tmp;
    });

    return await measurementLogs;
  }

  /*
   * ペットの体重記録をサーバへ登録する通信処理
   */
  async addMeasurementLog(petId, log) {
    let completeAddLog = false;
    return await this.db
      .collection(`pets`)
      .doc(`${petId}`)
      .collection(`measurementLogs`)
      .add({
        log,
      })
      .then(function() {
        completeAddLog = true;
        return completeAddLog;
      })
      .catch(function(error) {
        console.error("Error adding petWeight: ", error);
      });
  }

  /*
   * ペットの体重記録を更新する通信処理
   */
  async updateMeasurementLog(petId, logKey, log) {
    let completeUpdateLog = false;
    return await this.db
      .collection(`pets`)
      .doc(`${petId}`)
      .collection(`measurementLogs`)
      .doc(`${logKey}`)
      .set(
        {
          log: {
            date: log.date,
            weight: log.weight,
            unit: log.unit,
            memo: log.memo,
          },
        },
        { merge: true }
      )
      .then(function() {
        completeUpdateLog = true;
        return completeUpdateLog;
      })
      .catch(function(error) {
        console.error("Error updateMeasurementLog: ", error);
      });
  }

  /*
   * ペットの指定した体重情報を削除する
   */
  async deleteMeasurementLog(key, petId) {
    let completeDeleteLog = false;
    await this.db
      .collection(`pets`)
      .doc(`${petId}`)
      .collection(`measurementLogs`)
      .doc(key)
      .delete()
      .then(function() {
        completeDeleteLog = true;
      })
      .catch(function(error) {
        console.error("Error delete Log: ", error);
      });
    await this.getMeasurementLogs(petId);
    return completeDeleteLog;
  }
}

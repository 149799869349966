<template>
  <!-- content -->
  <ion-content class="ion-padding menu-pages">
    <div class="content-wrap">
      <div class="content">
        <Cats class="app-img" />
        <h1 class="page-title">アプリを更新してください</h1>
        <section>
          <p>
            新しいバージョンがリリースされました。<br />
            安心・快適にお使いいただくために<br />
            Apple Store もしくは Google play Storeから<br />
            アプリを新しいバージョンに更新してください。
          </p>
        </section>
      </div>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { arrowBack } from "ionicons/icons";
import Cats from "@/../public/assets/images/Cats";

/* サービス */

import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

export default defineComponent({
  name: "aboutThisApp",
  data: function() {
    return {
      arrowBack,
    };
  },
  async created() {},
  components: {
    IonContent,
    Cats,
  },
  methods: {
    /* モーダルを消す */
    async dismiss() {
      return await modalController.dismiss();
    },
  },
});
</script>

<style lang="scss" scoped>
.content-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .content {
    padding: 0 1.2rem 8px;
    text-align: center;
  }
}
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
li {
  list-style-type: none;
  font-size: 0.9rem;
}
.content p {
  line-height: 2;
  font-size: 1rem;
}
.app-img {
  display: block;
  width: 70%;
  max-width: 230px;
  margin: 0 auto 32px;
  height: auto;
}
</style>

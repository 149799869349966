<template>
  <h1 class="page-title">特定商取引法に基づく表示</h1>
  <section>
    <ul>
      <li>1. 事業者の名称：二角形</li>

      <li>2. 代表者または通信販売に関する業務の責任者の氏名：片岡彩子</li>

      <li>3. 住所: 〒856-0012 長崎県大村市黒木町766-10</li>

      <li>4. 電話番号: 080-7882-4711</li>
      <!-- TODO: ☆更新時確認  -->
      <li>5. メールアドレス: usersvoice@pet-weight-chart.com</li>

      <li>
        6. 商品の販売価格・サービスの対価:
        各商品・サービスのご購入ページにて表示する価格
      </li>

      <li>
        7. 対価以外に必要となる費用:
        <!--配送料：「配送料一覧」（※リンクを貼る）を参照※なお、-->インターネット接続料金その他の電気通信回線の通信に関する費用はお客様にて別途ご用意いただく必要があります（金額は、お客様が契約した各事業者が定める通り）。
      </li>

      <!--<li>8.	支払方法：クレジット決済、コンビニ決済</li>

        <li>9.	代金の支払時期：【クレジットカード】ご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせください。【コンビニ決済】ご注文から3日以内にお支払いいただきます。</li>

        <li>10.	商品引渡しまたはサービス提供の時期：【クレジットカード】クレジットカード利用の承認が下りた後、○○日以内に発送します。【コンビニ決済】代金入金確認次第、すみやかに商品を発送します。</li>-->

      <li>
        8.返品・キャンセルに関する特約：
        本サイトで販売する商品・サービスについては、購入手続完了後の返品またはキャンセルをお受けいたしません。なお、商品・サービスに瑕疵がある場合は、利用規約の定めに従って対応します。商品がソフトウェアの場合、動作環境及び必要スペックはご購入ページで表示しています。特別な販売条件または提供条件がある商品またはサービスについては、各商品またはサービスの購入ページにおいて条件を表示します。
      </li>
    </ul>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { arrowBack } from "ionicons/icons";

/* サービス */

import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

export default defineComponent({
  name: "SpecifiedCommercialTransactionsActModal",
  data: function() {
    return {
      arrowBack,
    };
  },
  async created() {},
  components: {},
  methods: {},
});
</script>

<style lang="scss" scoped>
p,
li {
  line-height: 2;
  font-size: 0.9rem;
}
</style>

<template>
  <!-- header -->
  <div class="modal-header">
    <button @click="dismiss" class="white-button">
      <ion-icon :icon="arrowBack"></ion-icon>
      <span class="button-label">戻る</span>
    </button>

    <button v-if="addOrEdit === 'add' && !petName" class="disabled">
      登録
    </button>

    <button
      v-if="addOrEdit === 'add' && petName"
      class="primary"
      @click="addData"
    >
      登録
    </button>

    <button
      v-if="addOrEdit === 'edit' && (!petName || !changeFlag)"
      class="disabled"
    >
      更新
    </button>

    <button
      v-if="addOrEdit === 'edit' && petName && changeFlag"
      class="primary"
      @click="updateData"
    >
      更新
    </button>
  </div>

  <!-- content -->
  <ion-content class="ion-padding">
    <!-- ペットの画像 -->
    <div class="pet_img_wrapper">
      <img v-if="photoSrc !== ''" :src="photoSrc" class="pet_img" />
      <div v-else class="pet_img">
        <ion-icon :icon="paw"></ion-icon>
      </div>

      <button v-if="photoSrc" @click="clearPhoto()" class="button_normal">
        <span class="button_with_icon__inner">
          <ion-icon :icon="closeOutline"></ion-icon>
          <span>削除</span>
        </span>
      </button>

      <div v-else id="attachment">
        <label class="button_normal">
          <input
            type="file"
            @change="handleChangeFile"
            name="写真を登録"
            class="fileinput"
          />
          <span class="button_with_icon__inner">
            <ion-icon :icon="image"></ion-icon>
            <span>写真を登録</span>
          </span>
        </label>
      </div>
    </div>
    <!-- 名前など -->
    <div class="input-wrapper">
      <div>
        <ion-label position="stacked">
          名前
          <span class="required-label">*必須</span>
        </ion-label>
        <ion-input
          placeholder="例）はむちゃん"
          :value="petName"
          @input="petName = $event.target.value"
          class="input-txt"
        ></ion-input>
      </div>
      <div v-if="!petName" class="required-comment">*入力してください</div>

      <div>
        <ion-label position="stacked">動物の種類</ion-label>
        <ion-input
          placeholder="例）ゴールデンハムスター"
          :value="petType"
          @input="petType = $event.target.value"
        ></ion-input>
      </div>

      <div class="select-unit">
        <ion-label
          position="stacked"
          class="sc-ion-label-md-h sc-ion-label-md-s md label-stacked hydrated"
          >重さの単位</ion-label
        >
        <div class="unit-button-wrapper">
          <div class="choice">
            <input
              type="radio"
              id="g"
              name="unit"
              value="g"
              v-model="petWeightUnit"
            />
            <label for="g" class="button_normal">g</label>
          </div>

          <div class="choice">
            <input
              type="radio"
              id="kg"
              name="unit"
              value="kg"
              v-model="petWeightUnit"
            />
            <label for="kg" class="button_normal">kg</label>
          </div>

          <div class="choice">
            <input
              type="radio"
              id="oz"
              name="unit"
              value="oz"
              v-model="petWeightUnit"
            />
            <label for="oz" class="button_normal">oz</label>
          </div>

          <div class="choice">
            <input
              type="radio"
              id="lb"
              name="unit"
              value="lb"
              v-model="petWeightUnit"
            />
            <label for="lb" class="button_normal">lb</label>
          </div>

          <div class="choice">
            <input
              v-if="petWeightUnitOther"
              type="radio"
              id="other"
              name="unit"
              value
              v-model="petWeightUnit"
              checked
              :class="[petWeightUnitOther === true ? 'checked' : '']"
            />
            <input
              v-else
              type="radio"
              id="other"
              name="unit"
              value
              v-model="petWeightUnit"
            />
            <label for="other" class="button_normal">他</label>
          </div>
        </div>
      </div>

      <div v-if="petWeightUnitOther" class="unit-input-other">
        <ion-label position="stacked">★他：単位を入力してください</ion-label>
        <ion-input
          placeholder="例）mg"
          :value="petWeightUnit"
          @input="petWeightUnit = $event.target.value"
        ></ion-input>
      </div>

      <div class="date-picker-wrapper">
        <ion-label position="stacked">お迎えした日</ion-label>
        <button
          v-if="dayWelcomeClearFlag === false && dayWelcome !== ''"
          class="clear-button"
          @click="clearDate('dayWelcome')"
        >
          <ion-icon name="close-circle"></ion-icon>
        </button>
        <div class="ion-datetime-wrap">
          <ion-datetime
            id="dayWelcome"
            placeholder="例）2020/01/01"
            min="1900-01-01"
            max="2030-12-31"
            :value="dayWelcome"
            @ionChange="dayWelcome = $event.target.value"
            day-short-names="[日, 月, 火, 水, 木, 金, 土]"
            display-format="YYYY/MM/DD (DDD)"
            month-short-names="1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12"
            @click="clickIonDatetime('dayWelcome')"
          ></ion-datetime>
          <div class="ion-datetime-bg">例）2020/01/01</div>
        </div>
      </div>

      <div class="date-picker-wrapper">
        <ion-label position="stacked">誕生日</ion-label>
        <button
          v-if="dayBirthClearFlag === false && dayBirth !== ''"
          class="clear-button"
          @click="clearDate('dayBirth')"
        >
          <ion-icon name="close-circle"></ion-icon>
        </button>
        <div class="ion-datetime-wrap">
          <ion-datetime
            id="dayBirth"
            placeholder="例）2020/01/01"
            min="1900-01-01"
            max="2030-12-31"
            :value="dayBirth"
            @ionChange="dayBirth = $event.target.value"
            day-short-names="[日, 月, 火, 水, 木, 金, 土]"
            display-format="YYYY/MM/DD (DDD)"
            month-short-names="1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12"
            @click="clickIonDatetime('dayBirth')"
          ></ion-datetime>
          <div class="ion-datetime-bg">例）2020/01/01</div>
        </div>
      </div>
      <div class="date-picker-wrapper">
        <ion-label position="stacked">さよならした日</ion-label>
        <button
          v-if="dayDeathClearFlag === false && dayDeath !== ''"
          class="clear-button"
          @click="clearDate('dayDeath')"
        >
          <ion-icon name="close-circle"></ion-icon>
        </button>
        <div class="ion-datetime-wrap">
          <ion-datetime
            id="dayDeath"
            placeholder="例）2020/01/01"
            min="1900-01-01"
            max="2030-12-31"
            :value="dayDeath"
            @ionChange="dayDeath = $event.target.value"
            day-short-names="[日, 月, 火, 水, 木, 金, 土]"
            display-format="YYYY/MM/DD (DDD)"
            month-short-names="1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12"
            @click="clickIonDatetime('dayDeath')"
          ></ion-datetime>
          <div class="ion-datetime-bg">例）2020/01/01</div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonIcon,
  modalController,
  IonDatetime,
  IonLabel,
  IonInput,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { closeOutline, arrowBack, paw, image } from "ionicons/icons";

/* サービス */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { UserService } from "@/services/user.js";

import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

/* ユーティリティ */
import Utils from "@/services/utils.js";

/* 画像圧縮のためのプラグイン */
import loadImage from "blueimp-load-image";

export default defineComponent({
  name: "AddOrEditPetModal",
  inheritAttrs: false,
  props: {
    petNameEdit: { type: String, default: "" }, // ペットの名前
    petTypeEdit: { type: String, default: "" }, // ペットの種類
    petWeightUnitEdit: { type: String, default: "" }, // 重さの単位
    petWelcomeEdit: { type: Object, default: null },
    petBirthEdit: { type: Object, default: null },
    petDeathEdit: { type: Object, default: null },
    petAvatarURLEdit: { type: String, default: "" }, // ペットの写真, photoSrc に入れる
    petAvatarFileName: { type: String, default: "" }, // ペットのAvatar画像のファイル名 削除する時に使う
  },
  data: function() {
    return {
      addOrEdit: "add",
      closeOutline,
      arrowBack,
      paw,
      image,
      petProfile: {},
      petName: "", // ペットの名前
      petType: "", // ペットの種類
      petWeightUnit: "",
      petBirth: "",
      petDeath: "",
      petAvatarURL: "", // ペットの写真URL,
      dayWelcome: "",
      timeWelcome: Utils.getNowTime(1),
      dayBirth: "",
      // 誕生日。DBに登録する際にはこの日付を元に「Wed Jun 02 2021 22:33:00 GMT+0900 (日本標準時）」 形式に変換してから登録する。
      // プロフィールeditの場合、誕生日の登録があれば「2021/06/02」形式で最初登録されている。
      // 一度でも日付が変更されると「2021-10-02T00:00:00+09:00」形式になる。
      timeBirth: Utils.getNowTime(1),
      dayDeath: "", // 命日。DBに登録する際にはこの日付を元に変換してから登録する。
      timeDeath: Utils.getNowTime(1),
      createdAt: new Date(),
      updatedAt: new Date(),
      userService: new UserService(firebase),
      tempPhoto: "",
      photoSrc: "", // photoSrc = カメラから撮った画像が詰まるやつ。最初は写真なし（null）
      changeFlag: false,
      dayWelcomeClearFlag: false,
      dayBirthClearFlag: false,
      dayDeathClearFlag: false,
    };
  },
  async created() {
    this.dayjs = dayjs;
    this.addOrEdit = "add";
    if (this.petNameEdit !== null) {
      this.addOrEdit = "edit";
      this.petName = this.petNameEdit;
      this.petWeightUnit = this.petWeightUnitEdit;
      this.petType = this.petTypeEdit;
      this.photoSrc = this.petAvatarURLEdit;
      if (this.petWelcomeEdit !== null) {
        this.dayWelcome = this.formatTimestamp(this.petWelcomeEdit.toDate()); // 2021/06/02 形式
      }
      if (this.petBirthEdit !== null) {
        this.dayBirth = this.formatTimestamp(this.petBirthEdit.toDate()); // 2021/06/02 形式
      }
      if (this.petDeathEdit !== null) {
        this.dayDeath = this.formatTimestamp(this.petDeathEdit.toDate()); // 2021/06/02 形式
      }
    } else {
      this.petWeightUnit = "g";
    }
  },
  components: {
    IonContent,
    IonIcon,
    IonDatetime,
    IonLabel,
    IonInput,
  },
  watch: {
    petName: function() {
      this.switchChangeFlag();
    },
    petType: function() {
      this.switchChangeFlag();
    },
    photoSrc: function() {
      this.switchChangeFlag();
    },
    petWeightUnit: function() {
      this.switchChangeFlag();
    },
    dayWelcome: function() {
      this.switchChangeFlag();
    },
    dayBirth: function() {
      this.switchChangeFlag();
    },
    dayDeath: function() {
      this.switchChangeFlag();
    },
    dayWelcomeClearFlag: function() {
      if (this.dayWelcomeClearFlag === true) {
        document.getElementById("dayWelcome").classList.add("clear");
        // this.dayWelcome === "";
        // this.petProfile.dayWelcome = "";
      } else {
        document.getElementById("dayWelcome").classList.remove("clear");
      }
    },
    dayBirthClearFlag: function() {
      if (this.dayBirthClearFlag === true) {
        document.getElementById("dayBirth").classList.add("clear");
        // this.dayBirth === "";
        // this.petProfile.dayBirth = "";
      } else {
        document.getElementById("dayBirth").classList.remove("clear");
      }
    },
    dayDeathClearFlag: function() {
      if (this.dayDeathClearFlag === true) {
        document.getElementById("dayDeath").classList.add("clear");
        // this.dayDeath === "";
        // this.petProfile.dayDeath = "";
      } else {
        document.getElementById("dayDeath").classList.remove("clear");
      }
    },
  },
  computed: {
    selectedPetId: function() {
      return this.$store.state.selectedPetId;
    },
    petWeightUnitOther: function() {
      if (
        this.petWeightUnit === "g" ||
        this.petWeightUnit === "kg" ||
        this.petWeightUnit === "oz" ||
        this.petWeightUnit === "lb"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    /* モーダルを消す */
    async dismiss() {
      return await modalController.dismiss();
    },
    /* 画像圧縮 */
    async handleChangeFile(e) {
      const { files } = e.target;
      const canvas = await loadImage(files[0], {
        maxWidth: 192, // 64 * 3
        canvas: true,
      });
      canvas.image.toBlob((blob) => {
        this.photoSrc = URL.createObjectURL(blob);
        this.tempPhoto = blob;
      });
    },
    /* 写真を消す */
    clearPhoto() {
      this.tempPhoto = "";
      this.photoSrc = "";
    },
    /* 日付を消す */
    clearDate(key) {
      switch (key) {
        case "dayWelcome":
          this.dayWelcomeClearFlag = true;
          this.switchChangeFlag();
          // this.dayWelcome = "";
          // this.petProfile.dayWelcome = "";
          break;
        case "dayBirth":
          this.dayBirthClearFlag = true;
          this.switchChangeFlag();
          // this.dayBirth = "";
          // this.petProfile.dayBirth = "";
          break;
        case "dayDeath":
          this.dayDeathClearFlag = true;
          this.switchChangeFlag();
          // this.dayDeath = "";
          // this.petProfile.dayDeath = "";
          break;
        default:
          break;
      }
    },
    clickIonDatetime(key) {
      switch (key) {
        case "dayWelcome":
          this.dayWelcomeClearFlag = false;
          this.switchChangeFlag();
          break;
        case "dayBirth":
          this.dayBirthClearFlag = false;
          this.switchChangeFlag();
          break;
        case "dayDeath":
          this.dayDeathClearFlag = false;
          this.switchChangeFlag();
          break;
      }
    },
    /* データをサーバへ送るために、petProfileに詰めて渡す */
    async addData() {
      /**
       * ペットを追加した時に、userのid名のドキュメントが存在しなければ
       * users/{userId}/ を作って、そこに
       * userId、createdAt、updatedAt を作る
       * 存在すれば、updatedAt を更新する
       */
      await this.userService.addOrUpdateUserDocument();

      // const ctx = this;
      let photoUrl = "";
      let targetTimeWelcome = "";
      let targetTimeBirth = "";
      let targetTimeDeath = "";
      let tempPetWelcome = null;
      let tempPetBirth = null;
      let tempPetDeath = null;

      this.petProfile = {
        petName: this.petName,
        petType: this.petType,
        petWeightUnit: this.petWeightUnit,
        petAvatarURL: "",
        petWelcome: null,
        petBirth: null,
        petDeath: null,
        createdAt: this.createdAt,
        updatedAt: this.updatedAt,
      };

      let petImagesList = {
        petAvatarFileName: "",
      };

      if (this.dayWelcomeClearFlag === true) {
        this.petProfile.petWelcome = null;
      } else if (this.dayWelcome !== "") {
        targetTimeWelcome = `${this.dayWelcome.split("T")[0]} ${
          this.timeWelcome
        }`;
        tempPetWelcome = new Date(targetTimeWelcome.replace(/-/g, "/"));
        this.petProfile.petWelcome = tempPetWelcome;
      }

      if (this.dayBirthClearFlag === true) {
        this.petProfile.petBirth = null;
      } else if (this.dayBirth !== "") {
        targetTimeBirth = `${this.dayBirth.split("T")[0]} ${this.timeBirth}`;
        tempPetBirth = new Date(targetTimeBirth.replace(/-/g, "/"));
        this.petProfile.petBirth = tempPetBirth;
      }

      if (this.dayDeathClearFlag === true) {
        this.petProfile.petDeath = null;
      } else if (this.dayDeath !== "") {
        targetTimeDeath = `${this.dayDeath.split("T")[0]} ${this.timeDeath}`;
        tempPetDeath = new Date(targetTimeDeath.replace(/-/g, "/"));
        this.petProfile.petDeath = tempPetDeath;
      }

      // まずfirebaseに画像以外を登録する。thisを渡したい場合は、引数を追加してctxなどにthisをつめて渡す。
      const addedPetId = await this.userService.addPetProfile(
        this.petProfile,
        petImagesList
      );

      // 自動で発行されたaddedPetIdを返してもらって受け取る。
      this.$store.commit("setSelectedPetId", addedPetId);

      // 写真がなにかしら選択されていたら
      if (this.tempPhoto !== "") {
        // 写真の拡張子
        // const imgType = this.tempPhoto.format;
        const splitText = this.tempPhoto.type.split("/");
        const splitLenght = this.tempPhoto.type.split("/").length;
        const imgType = splitText[splitLenght - 1];

        photoUrl = await this.userService.addPetProfileAvatarAndGetAvatarURL(
          addedPetId,
          this.tempPhoto,
          imgType
        );
        await this.userService.addPetProfileAvatarURL(
          addedPetId,
          photoUrl,
          imgType
        );
      }

      // storeにpetの情報を入れる
      const petProfileAddedAvatar = {
        petName: this.petName,
        petType: this.petType,
        petWeightUnit: this.petWeightUnit,
        petWelcome: this.petWelcome,
        petBirth: this.petBirth,
        petDeath: this.petDeath,
        petAvatarURL: photoUrl,
      };
      this.$store.commit("setSelectedPetProfile", petProfileAddedAvatar);
      await this.$router.replace("/history");

      await modalController.dismiss();
    },
    async updateData() {
      /**
       * ペット情報を更新した時に、
       * userのid名のドキュメントが存在しなければ
       * users/{userId}/ を作って、そこに
       * userId、createdAt、updatedAt を作る。
       * 既に存在していれば、updatedAt を更新する
       * 1匹目の登録時にドキュメントを作成するので、
       * 通常は既に存在するはず。
       */
      await this.userService.addOrUpdateUserDocument();

      let photoUrl = "";
      let targetTimeWelcome = "";
      let targetTimeBirth = "";
      let targetTimeDeath = "";
      let tempPetWelcome = null;
      let tempPetBirth = null;
      let tempPetDeath = null;

      this.petProfile = {
        petName: this.petName,
        petType: this.petType,
        petWeightUnit: this.petWeightUnit,
        petWelcome: null, // 下で日付変換して入れる
        petBirth: null, // 下で日付変換して入れる
        petDeath: null, // 下で日付変換して入れる
      };

      if (this.dayWelcomeClearFlag === true) {
        this.petProfile.petWelcome = null;
      } else if (this.dayWelcome !== "") {
        targetTimeWelcome = `${this.dayWelcome.split("T")[0]} ${
          this.timeWelcome
        }`;
        tempPetWelcome = new Date(targetTimeWelcome.replace(/-/g, "/"));
        this.petProfile.petWelcome = tempPetWelcome;
      }

      if (this.dayBirthClearFlag === true) {
        this.petProfile.petBirth = null;
      } else if (this.dayBirth !== "") {
        // this.dayBirth
        // プロフィールeditの場合、誕生日の登録があれば「2021/06/02」形式で最初登録されている。
        // 一度でも日付が変更されると「2021-10-02T00:00:00+09:00」形式になる。
        targetTimeBirth = `${this.dayBirth.split("T")[0]} ${this.timeBirth}`;
        // targetTimeBirth 2021/06/02 22:25
        tempPetBirth = new Date(targetTimeBirth.replace(/-/g, "/"));
        // tempPetBirth Wed Jun 02 2021 22:25:00 GMT+0900 (日本標準時)
        this.petProfile.petBirth = tempPetBirth;
      }

      if (this.dayDeathClearFlag === true) {
        this.petProfile.petDeath = null;
      } else if (this.dayDeath !== "") {
        targetTimeDeath = `${this.dayDeath.split("T")[0]} ${this.timeDeath}`;
        tempPetDeath = new Date(targetTimeDeath.replace(/-/g, "/"));
        this.petProfile.petDeath = tempPetDeath;
      }

      // もしアバター画像変更あれば、画像登録する。
      // 写真がなにかしら新しく選択し直されていたら
      if (this.photoSrc !== this.petAvatarURLEdit) {
        // 新しい画像が選択されている時（Avatar画像を更新する時）
        if (this.tempPhoto !== "") {
          // 写真の拡張子
          const splitText = this.tempPhoto.type.split("/");
          const splitLenght = this.tempPhoto.type.split("/").length;
          const imgType = splitText[splitLenght - 1];

          photoUrl = await this.userService.addPetProfileAvatarAndGetAvatarURL(
            this.selectedPetId,
            this.tempPhoto,
            imgType
          );

          // 画像URLをCloud Firestoreに登録
          await this.userService.addPetProfileAvatarURL(
            this.selectedPetId,
            photoUrl,
            imgType
          );
        }

        // Avatar画像削除する時
        if (this.tempPhoto === "") {
          // StrageからAvatar画像「avatar.xxx」消す処理
          await this.userService.deletePetProfileAvatar(
            this.selectedPetId,
            this.petAvatarFileName
          );
          // Cloud FirestoreのAvatarのURLと、イメージ一覧から画像名「avatar.xxx」を削除する処理
          await this.userService.deletePetProfileAvatarURL(this.selectedPetId);
          photoUrl = "";
        }
      }

      // 画像変更なければ、photoUrlにpropsで渡ってきた画像のURLを入れる
      if (this.photoSrc === this.petAvatarURLEdit) {
        photoUrl = this.petAvatarURLEdit;
      }

      // アバター画像変更あれば、photoUrlに既に変更後のURL入ってる
      await this.userService.updatePetProfile(
        this.selectedPetId,
        this.petProfile
      );

      // storeにpetの情報を入れる
      const petProfileAddedAvatar = {
        petName: this.petName,
        petType: this.petType,
        petWeightUnit: this.petWeightUnit,
        petWelcome: this.petWelcome,
        petBirth: this.petBirth,
        petDeath: this.petDeath,
        petAvatarURL: photoUrl,
      };
      this.$store.commit("setSelectedPetProfile", petProfileAddedAvatar);
      await this.$router.replace("/history");

      await modalController.dismiss();
    },
    formatTimestamp(date) {
      return dayjs(date).format("YYYY/MM/DD");
    },
    /*
     * 変更が会った時だけ更新ボタン表示させる判定用
     */
    switchChangeFlag() {
      let formatedWelcomeEdit = null;
      if (this.petWelcomeEdit !== null) {
        formatedWelcomeEdit = this.formatTimestamp(
          this.petWelcomeEdit.toDate()
        );
      }
      let formatedWelcome = "";
      if (this.dayWelcome !== "") {
        const targetTimeWelcome = `${this.dayWelcome.split("T")[0]}`;
        formatedWelcome = targetTimeWelcome.replace(/-/g, "/");
      }
      // formatedBirthEdit: this.petBirthEdit が設定されている時、日付比較用にフォーマットする
      let formatedBirthEdit = null;
      if (this.petBirthEdit !== null) {
        formatedBirthEdit = this.formatTimestamp(this.petBirthEdit.toDate());
      }
      // formatedBirth: this.dayBirth が "" ではない時（日付いじった時）、日付比較用にフォーマットする
      let formatedBirth = "";
      if (this.dayBirth !== "") {
        const targetTimeBirth = `${this.dayBirth.split("T")[0]}`;
        formatedBirth = targetTimeBirth.replace(/-/g, "/");
      }
      // formatedDeathEdit: this.petDeathEdit が設定されている時、日付比較用にフォーマットする
      let formatedDeathEdit = null;
      if (this.petDeathEdit !== null) {
        formatedDeathEdit = this.formatTimestamp(this.petDeathEdit.toDate());
      }
      // formatedDeath: this.dayDeath が "" ではない時（日付いじった時）、日付比較用にフォーマットする
      let formatedDeath = "";
      if (this.dayDeath !== "") {
        const targetTimeDeath = `${this.dayDeath.split("T")[0]}`;
        formatedDeath = targetTimeDeath.replace(/-/g, "/");
      }
      if (
        this.petName !== this.petNameEdit ||
        this.petType !== this.petTypeEdit ||
        this.photoSrc !== this.petAvatarURLEdit ||
        this.petWeightUnit !== this.petWeightUnitEdit ||
        /*
         * Welcome
         */
        // 元々登録してあった日付は空
        // かつ お迎えした日の日付が編集された状態
        // かつ 日付クリアフラグはfalseの状態
        (this.petWelcomeEdit === null &&
          this.dayWelcome !== "" &&
          this.dayWelcomeClearFlag === false) ||
        // お迎えした日の日付が編集された状態
        // かつ 元々登録してあった日付と異なる
        // かつ 日付クリアフラグはfalseの状態
        (this.petWelcomeEdit !== null &&
          formatedWelcome !== formatedWelcomeEdit &&
          this.dayWelcome !== "" &&
          this.dayWelcomeClearFlag === false) ||
        // 元々登録していた日付が空でなかった
        // かつ クリアされた場合（日付クリアフラグtrue）
        (this.petWelcomeEdit !== null && this.dayWelcomeClearFlag === true) ||
        /*
         * Birth
         */
        // 元々登録してあった日付は空
        // かつ お迎えした日の日付が編集された状態
        // かつ 日付クリアフラグはfalseの状態
        (this.petBirthEdit === null &&
          this.dayBirth !== "" &&
          this.dayBirthClearFlag === false) ||
        // お迎えした日の日付が編集された状態
        // かつ 元々登録してあった日付と異なる
        // かつ 日付クリアフラグはfalseの状態
        (this.petBirthEdit !== null &&
          formatedBirth !== formatedBirthEdit &&
          this.dayBirth !== "" &&
          this.dayBirthClearFlag === false) ||
        // 元々登録していた日付が空でなかった
        // かつ クリアされた場合（日付クリアフラグtrue）
        (this.petBirthEdit !== null && this.dayBirthClearFlag === true) ||
        /*
         * Death
         */
        // 元々登録してあった日付は空
        // かつ お迎えした日の日付が編集された状態
        // かつ 日付クリアフラグはfalseの状態
        (this.petDeathEdit === null &&
          this.dayDeath !== "" &&
          this.dayDeathClearFlag === false) ||
        // お迎えした日の日付が編集された状態
        // かつ 元々登録してあった日付と異なる
        // かつ 日付クリアフラグはfalseの状態
        (this.petDeathEdit !== null &&
          formatedDeath !== formatedDeathEdit &&
          this.dayDeath !== "" &&
          this.dayDeathClearFlag === false) ||
        // 元々登録していた日付が空でなかった
        // かつ クリアされた場合（日付クリアフラグtrue）
        (this.petDeathEdit !== null && this.dayDeathClearFlag === true)
      ) {
        this.changeFlag = true;
      } else {
        this.changeFlag = false;
      }
    },
  },
});
</script>

<style scoped>
/* ペットの画像 */
.pet_img_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pet_img {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;
}
.pet_img ion-icon {
  color: #ffffff;
  width: 24px;
  height: 24px;
}

/* input */
.input-wrapper {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 40px;
  padding-bottom: 64px;
}
.input-wrapper ion-item:not(:last-child) {
  margin-bottom: 20px;
}
</style>
<style lang="scss" scoped>
.modal-header {
  .white-button {
    background: #fff;
    padding: 1rem 1.5rem 0.875rem 1rem;
  }
}
/* g or kg */
.select-unit {
  padding-top: 12px;
  .unit-button-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 20px;
    justify-content: space-between;
    .choice {
      width: 18%;
    }
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"]:checked + label,
  input[type="radio"].checked + label {
    background: #fff;
    border: solid 3px #f8a505;
    font-weight: bold;
  }
}
.unit-input-other {
  opacity: 0;
  animation-name: fadein-top;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  margin-top: -12px;
}
@keyframes fadein-top {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 画像圧縮のfileのinput */
#attachment {
  position: relative;
  label input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
/* 必須入力ラベル */
.required-label {
  margin-left: 0.5rem;
  color: #f26600;
  font-size: 0.8rem;
}
/* 必須入力コメント */
.required-comment {
  color: #f26600;
  margin-top: -0.75rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}
/* 日付クリアするボタン */
.date-picker-wrapper {
  position: relative;
  margin-bottom: 0.5rem;
  .ion-datetime-wrap {
    position: relative;
    margin-top: 0.25rem;
    margin-bottom: 1.5rem;
    height: 2.75rem;
    ion-datetime,
    .ion-datetime-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #fff;
      border-radius: 1rem;
      padding-inline-start: var(--padding-start);
      padding-inline-end: var(--padding-end);
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
    }
    ion-datetime.clear {
      opacity: 0;
    }
    ion-datetime {
      z-index: 0;
    }
    .ion-datetime-bg {
      z-index: -1;
      color: var(--ion-color-step-400, #999999);
    }
  }
  .clear-button {
    background: none;
    position: absolute;
    right: 0.5rem;
    bottom: 0.2rem;
    z-index: 100;
    padding: 0.4rem;
    ion-icon {
      width: 1.25rem;
      height: 1.25rem;
      color: #f8a505;
    }
  }
}
/* 入力エリア */
ion-input {
  background: #fff;
  border-radius: 1rem;
  margin-top: 8px;
  margin-bottom: 20px;
  font-weight: normal;
}
</style>
<style scoped>
ion-input >>> input {
  padding-inline-start: var(--padding-start) !important;
  padding-left: 1.25rem !important;
  padding-inline-end: var(--padding-end) !important;
  padding-top: 0.82rem !important;
  padding-bottom: 0.82rem !important;
}
</style>

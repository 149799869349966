<template>
  <!-- header -->
  <div class="modal-header">
    <button @click="dismiss" class="white-button">
      <ion-icon :icon="arrowBack"></ion-icon>
      <span class="button-label">戻る</span>
    </button>
  </div>

  <!-- content -->
  <ion-content class="ion-padding menu-pages">
    <h1 class="page-title">このアプリについて</h1>

    <section>
      <h2>動物たちと楽しい時間を</h2>
      <p>
        このアプリは、一緒に暮らすペット（リス）の体重を手軽に記録したくて作った、個人制作アプリです。
      </p>
      <p>
        なんだか体型が丸くなる時期がある気がするので「季節に由来する増減なのか？」知りたくて、体重の変化の記録を取りたくて作ってみました。
      </p>
      <p>
        まだ手探り状態ですが、とりあえず、まずはアプリを公開できてよかったです。<br />
        引き続きアップデートしていけたらと思っています。
      </p>
      <p>動物との楽しい毎日を過ごせる一助となれば幸いです。</p>
    </section>

    <section class="message">
      <p>
        P.S.<br />趣味でのんびり作っておりますので、すぐに何か対応できるかというと難しいかもしれませんが、<br />何かお気づきの点やご要望等ございましたら、下記までよろしくお願いいたします。
      </p>
      <p>
        mail:<br />
        <a
          href="mailto:usersvoice@pet-weight-chart.com?subject=ペットの体重グラフにす〜る：ユーザーの声&amp;body=お問い合わせ内容をご入力ください。%0D%0A%0D%0Aお名前：%0D%0Aお問い合わせ内容：%0D%0A%0D%0A（不具合報告の場合）発生したページ：%0D%0A"
        >
          usersvoice@pet-weight-chart.com
        </a>
      </p>
      <p>
        twitter:<br />
        <a href="https://twitter.com/DigonDev" target="_blank">
          @DigonDev
        </a>
      </p>
    </section>

    <section>
      <h2>利用料金（無料）</h2>
      <p>
        現在、無料でご利用いただけます。<br />（将来的にも「知らない間に利用料金が発生する」ことはありません。ご安心ください。）
      </p>
    </section>

    <section>
      <h2>今後の追加機能開発の予定：1</h2>
      <p>
        家族間でのペットの情報のシェアができるような機能と、そのための導線を追加する予定です。
      </p>
      <p>
        （例えば「療養中のペットの体重をご家族全員が気にしている時」などに役立つと考えています。）
      </p>
    </section>

    <section>
      <h2>今後の追加機能開発の予定：2</h2>
      <p>
        将来「ペットの種類別に統計を出す」機能を追加で開発する可能性があります。
      </p>
      <p>
        （例えば、「ハムスターの季節ごとの体重の増減割合」について、統計を取ったりなど。）
      </p>
      <p>
        飼い主さんの心配を解消できる機能になればいいな、と思っています。
      </p>
      <p>
        例えば
      </p>
      <p>
        「うちの子、夏と冬じゃだいぶ体重が違うけど、大丈夫かな？」
      </p>
      <p>
        「同じ種類のほかの子たちはどんな感じなんだろう？（でも体重の情報ってなかなかきけないし、珍しい生き物だからそもそも飼っている人が少ない…！情報が少ないなぁ。。。）」
      </p>
      <p>
        …という気持ちを解消できるアプリにできたらいいな、と考えています。
      </p>
      <p>
        現在はそのような機能はございません。（統計情報としてみなさまの情報を使っておりません。）<br />実装する際にはお知らせしますね。
      </p>
    </section>
  </ion-content>
</template>

<script>
import { IonContent, IonIcon, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { arrowBack } from "ionicons/icons";

/* サービス */

import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

export default defineComponent({
  name: "aboutThisApp",
  data: function() {
    return {
      arrowBack
    };
  },
  async created() {},
  components: {
    IonContent,
    IonIcon
  },
  methods: {
    /* モーダルを消す */
    async dismiss() {
      return await modalController.dismiss();
    }
  }
});
</script>

<style lang="scss" scoped>
.modal-header {
  .white-button {
    background: #fff;
    padding: 1rem 1.5rem 0.875rem 1rem;
  }
}
p {
  line-height: 2;
  font-size: 1rem !important;
  font-weight: normal;
}
.page-title {
  margin-bottom: 2rem;
}
section {
  h2 {
    border-bottom: solid 1px #e7b6b2;
    padding-bottom: 6px;
    &::before {
      content: "◉";
      color: #e7b6b2;
      margin-right: 4px;
    }
  }
}
</style>

<template>
  <!-- TODO: ☆更新時確認 -->
  <!-- 【】と↑を検索 -->
  <!-- content -->
  <h1 class="page-title">プライバシーポリシー</h1>
  <p>
    二角形（以下「当方」といいます。）は、当方の提供するサービス（以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
  </p>

  <section>
    <h2>1. 収集する利用者情報及び収集方法</h2>
    <p>
      本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当方が収集するものを意味するものとします。本サービスにおいて当方が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
    </p>

    <p>
      ■ ユーザーからご提供いただく情報<br />
      本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。
    </p>

    <ul>
      <!-- <li>・氏名、生年月日、性別、職業等プロフィールに関する情報</li> -->
      <li>・メールアドレス<!--、電話番号、住所等連絡先に関する情報--></li>
      <!-- <li>・クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報</li> -->
      <li>・ユーザーのペットの肖像を含む静止画情報</li>
      <li>
        ・入力フォームその他当方が定める方法を通じてユーザーが入力または送信する情報
      </li>
    </ul>

    <p>
      ■ ユーザーが本サービスの利用において、
      他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報<br />
      ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。
    </p>
    <ul>
      <li>・当該外部サービスでユーザーが利用するID</li>
      <li>
        ・その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報
      </li>
    </ul>

    <p>
      ■ ユーザーが本サービスを利用するにあたって、
      当方が収集する情報当方は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
    </p>
    <ul>
      <li>・リファラ</li>
      <li>・IPアドレス</li>
      <li>・サーバーアクセスログに関する情報</li>
      <li>・Cookie、ADID、IDFAその他の識別子</li>
    </ul>
  </section>

  <section>
    <h2>2. 利用目的</h2>
    <p>
      本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
    </p>

    <ul>
      <li>
        (1)
        本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため
      </li>
      <li>(2) ユーザーのトラフィック測定及び行動測定のため</li>
      <li>(3) 広告の配信、表示及び効果測定のため</li>
      <li>(4) 本サービスに関するご案内、お問い合わせ等への対応のため</li>
      <li>
        (5)
        本サービスに関する当方の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
      </li>
      <li>(6) 本サービスに関する規約等の変更などを通知するため</li>
    </ul>
  </section>

  <section>
    <h2>3. 通知・公表または同意取得の方法、利用中止要請の方法</h2>
    <p>
      3-1
      以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。<br />
      ■ 位置情報
    </p>

    <p>
      3-2
      ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当方は速やかに、当方の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当方所定の方法により本サービスを退会した場合に限り、当方はその収集又は利用を停止します。
    </p>
  </section>

  <!-- <section>
      <h2>4.	外部送信、第三者提供、情報収集モジュールの有無</h2>
      <p>4-1	本サービスでは、以下の提携先が、ユーザーの端末にCookieを保存し、これを利用して利用者情報を蓄積及び利用している場合があります。</p>

      <ul>
        <li>(1)	提携先</li>

        <li>(2)	上記提携先のプライバシーポリシーのURL</li>

        <li>(3)	上記提携先のオプトアウト（無効化）URL</li>

        <li>(4)	ユーザーが本サービスを利用するにあたって、当方がユーザーの個別同意に基づいて収集する情報当方は、ユーザーが3-1に定める方法により個別に同意した場合、当方は以下の情報を利用中の端末から収集します。<br/>
        ■ 位置情報</li>
      </ul>

      <p>4-2	本サービスには以下の情報収集モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者（日本国外にある者を含みます。）への利用者情報の提供を行います。</p>

      <ul>
        <li>(1)	情報収集モジュールの名称</li>

        <li>(2)	情報収集モジュールの提供者</li>

        <li>(3)	提供される利用者情報の項目</li>

        <li>(4)	提供の手段・方法</li>

        <li>(5)	上記提供者における利用目的</li>

        <li>(6)	上記提供者における第三者提供の有無</li>

        <li>(7)	上記提供者のプライバシーポリシーのURL</li>
      </ul>
    </section> -->

  <section>
    <h2>4. 第三者提供</h2>
    <p>
      当方は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
    </p>

    <ul>
      <li>
        (1)
        当方が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
      </li>
      <li>
        (2) 合併その他の事由による事業の承継に伴って個人情報が提供される場合
      </li>
      <li>
        (3)
        第4項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合
      </li>
      <li>
        (4)
        国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
      </li>
      <li>
        (5)
        その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
      </li>
    </ul>
  </section>

  <!-- <section>
      <h2>6.	共同利用</h2>
      <p>当方は、以下のとおりユーザーの個人情報を共同利用します。</p>

      <ul>
        <li>(1)	共同して利用される個人情報の項目</li>

        <li>(2)	共同して利用する者の範囲</li>

        <li>(3)	共同して利用する者の利用目的</li>

        <li>(4)	個人情報の管理について責任を有する者の氏名または名称</li>
      </ul>
    </section> -->

  <section>
    <h2>5. 個人情報の開示</h2>
    <p>
      当方は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当方が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり5,000円）を頂戴しておりますので、あらかじめ御了承ください。
    </p>
  </section>

  <section>
    <h2>6. 個人情報の訂正及び利用停止等</h2>
    <p>
      6-1
      当方は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
    </p>

    <p>
      6-2当方は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当方が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
    </p>

    <p>
      6-3個人情報保護法その他の法令により、当方が訂正等または利用停止等の義務を負わない場合は、6-1および6-2の規定は適用されません。
    </p>
  </section>

  <section>
    <h2>7. お問い合わせ窓口</h2>
    <p>
      ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。
    </p>

    <p>
      メールアドレス: usersvoice@pet-weight-chart.com<br />
      住所: 〒856-0012 長崎県大村市黒木町766-10 二角形 片岡<br />
      個人情報取扱責任者: 片岡彩子<br />
      電話: 080-7882-4711
    </p>
  </section>

  <section>
    <h2>8. プライバシーポリシーの変更手続</h2>
    <p>
      当方は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当方所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当方は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当方のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。
    </p>
  </section>

  <section>
    <!-- TODO: ★更新時確認-->
    <p>2022年8月6日制定</p>
    <!--<p>●年●月●日改定</p>-->
  </section>
</template>

<script>
import { defineComponent } from "vue";

/* サービス */

import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

export default defineComponent({
  name: "PrivacyPolicyContent",
  async created() {},
});
</script>

<style lang="scss" scoped>
p,
li {
  line-height: 2;
  font-size: 0.9rem;
  + li {
    margin-top: 0 !important;
  }
}
</style>

<template>
  <div class="confirm-wrap">
    <ion-card>
      <ion-card-header>
        <div class="log">
          <div class="date">{{ formattedDate }}</div>
          <!-- {{formatTimestamp(deleteLogDate.toDate())}} -->
          <div class="weight">
            <span class="num">{{ deleteLogWeight }}</span>
            <span class="unit">{{ deleteLogUnit }}</span>
          </div>
        </div>
      </ion-card-header>

      <ion-card-content>
        <p>本当に削除しますか？</p>
        <div class="buttons">
          <ion-buttons>
            <ion-button @click="cancel()" class="cancel-button"
              >キャンセル</ion-button
            >
            <ion-button
              color="danger"
              @click="deleteMeasurementLog(deleteLogKey)"
              >削除</ion-button
            >
          </ion-buttons>
        </div>
      </ion-card-content>
    </ion-card>
    <BackDrop class="backdrop" />
  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonButtons,
  IonButton,
} from "@ionic/vue";
import { defineComponent } from "vue";
import BackDrop from "@/components/BackDrop";

/* サービス */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { UserService } from "@/services/user.js";
import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

export default defineComponent({
  data: function() {
    return {
      userService: new UserService(firebase),
    };
  },
  async created() {
    this.dayjs = dayjs;
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonButtons,
    IonButton,
    BackDrop,
  },
  computed: {
    selectedPetId: function() {
      return this.$store.state.selectedPetId;
    },
    deleteLogKey: function() {
      return this.$store.state.deleteLogKey;
    },
    deleteLogDate: function() {
      return this.$store.state.deleteLogDate;
    },
    formattedDate: function() {
      return this.formatDate();
    },
    deleteLogWeight: function() {
      return this.$store.state.deleteLogWeight;
    },
    deleteLogUnit: function() {
      return this.$store.state.deleteLogUnit;
    },
    displayConfirmDeleteLogModal: function() {
      return this.$store.state.displayConfirmDeleteLogModal;
    },
    reloadMeasurementLogFlag: function() {
      return this.$store.state.reloadMeasurementLogFlag;
    },
  },
  methods: {
    async cancel() {
      {
        await this.$store.commit("setDeleteLogKey", "");
        await this.$store.commit("setDeleteLogDate", "");
        await this.$store.commit("setDeleteLogWeight", "");
        await this.$store.commit("setDeleteLogUnit", "");
        await this.$store.commit("setDisplayConfirmDeleteLogModal", false);
      }
    },
    async deleteMeasurementLog(deleteLogKey) {
      /**
       * 体重のログの削除時に、
       * userのid名のドキュメントが存在しなければ
       * users/{userId}/ を作って、そこに
       * userId、createdAt、updatedAt を作る。
       * 既に存在していれば、updatedAt を更新する
       * 1匹目の登録時にドキュメントを作成するので、
       * 通常は既に存在するはず。
       */
      await this.userService.addOrUpdateUserDocument();
      // Cloud Firestoreの中の該当するpetのデータを消し、エラーなく削除できたらtrueを返す
      const completeDeleteLog = await this.userService.deleteMeasurementLog(
        deleteLogKey,
        this.selectedPetId
      );

      // 削除が完了したらページをリロードする
      if (completeDeleteLog === true) {
        await this.$store.commit("setDeleteLogKey", "");
        await this.$store.commit("setDeleteLogDate", "");
        await this.$store.commit("setDeleteLogWeight", "");
        await this.$store.commit("setDeleteLogUnit", "");
        await this.$store.commit("setDisplayConfirmDeleteLogModal", false);
        // 成功したら、再描画するためのフラグ立てる
        await this.$store.commit("setReloadMeasurementLogFlag", true);
      }
    },
    formatTimestamp(date) {
      return dayjs(date).format("YYYY/MM/DD");
    },
    formatDate() {
      if (this.deleteLogDate !== "") {
        return this.formatTimestamp(this.deleteLogDate.toDate());
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.confirm-wrap {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
}
ion-card {
  background: #fff;
  z-index: 1000;
  min-width: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.backdrop {
  z-index: 100;
}
ion-card-header {
  text-align: center;
}
ion-card-title {
  font-size: 1.25rem;
}
ion-card-content {
  width: 100%;
}
ion-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.buttons {
  display: flex;
  margin-top: 1.5rem;
  width: 100%;
}
.cancel-button {
  padding-left: 0;
  margin-right: 2rem;
}
.pet_img {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-right: auto;
  margin-left: auto;
}
.pet_img ion-icon {
  color: #ffffff;
  width: 24px;
  height: 24px;
}
p {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 0.5rem;
}
.log {
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-top: 8px;
}
.date {
  font-size: 1.25rem;
  margin-right: 1rem;
}
.weight {
  font-size: 1.25rem;
}
</style>

<template>
  <ion-page class="page">
    <ion-content :fullscreen="true">
      <div class="content-wrap">
        <!--div class="pet_info-wrap" @click="openPetProfileModal()"-->
        <div class="pet_info-wrap">
          <!-- 画像 -->
          <img
            v-if="selectedPetProfile.petAvatarURL"
            :src="selectedPetProfile.petAvatarURL"
            class="pet_img"
          />
          <div v-else class="pet_img">
            <ion-icon :icon="paw"></ion-icon>
          </div>
          <!-- 情報 -->
          <div class="each-pet_info-text__wrapper">
            <span class="pet-type">{{ selectedPetProfile.petType }}</span>
            <span class="pet-name">{{ selectedPetProfile.petName }}</span>
          </div>
          <!--div class="icon">
            <ion-icon :icon="chevronDown"></ion-icon>
          </div-->
        </div>
        <!-- グラフ -->
        <div class="amChart-wrap">
          <div
            class="amChart"
            ref="chartdiv"
            v-bind:class="{ nodata: noMeasurementLog }"
          ></div>
        </div>
        <!-- 体重記録のデータが1件以上あるとき -->
        <div v-if="measurementLogs.length !== 0">
          <div
            v-for="(eachLog, index) in measurementLogs"
            :key="index"
            class="each-log"
          >
            <div class="each-log-header">
              <div class="date">
                {{ formatTimestamp(eachLog.log.date.toDate()) }}
              </div>
              <div class="weight">
                <span class="num">{{ eachLog.log.weight }}</span>
                <span class="unit">{{ selectedPetProfile.petWeightUnit }}</span>
              </div>
              <!-- 「…」ボタン -->
              <button
                class="option_menu_button"
                @click.self="openOptionMenu($event, index)"
              >
                <ion-icon
                  :icon="ellipsisHorizontal"
                  @click.self="openOptionMenu($event, index)"
                ></ion-icon>
              </button>
              <!-- オプションメニュー -->
              <!-- 「clickされたPets」のindex番号（clickedOptionMenuNum）と、「自身の」index番号が一致したら.activeを付与 -->
              <ul
                ref="oprionMenuRef"
                class="option_menu_list"
                v-bind:class="[clickedOptionMenuNum === index ? 'active' : '']"
              >
                <li
                  class="option_menu_list_item"
                  @click="openRecordWeightModal('edit', index)"
                >
                  編集
                </li>
                <li
                  class="option_menu_list_item"
                  @click="confirmDeleteLog(index)"
                >
                  削除
                </li>
                <li class="option_menu_list_item" @click="closeOptionMenu()">
                  キャンセル
                </li>
              </ul>
            </div>
            <div v-if="eachLog.log.memo" class="memo">
              {{ eachLog.log.memo }}
            </div>
          </div>
        </div>
        <!-- 体重の記録はこのボタンから -->
        <div v-if="measurementLogs.length === 0" class="guide-record-button">
          体重の記録はこちらから
        </div>
        <div
          v-if="measurementLogs.length === 0"
          class="guide-record-button-arrow"
        ></div>
      </div>
    </ion-content>
    <ion-fab
      slot="fixed"
      ref="fab"
      :class="[downFabZindx === true ? 'down-fab-z-index' : '']"
    >
      <ion-fab-button @click="openRecordWeightModal('add', null)">
        <IconPen class="icon-pen" />
      </ion-fab-button>
    </ion-fab>
  </ion-page>
</template>

<script>
/* Vue With Ionic */
import { defineComponent } from "vue";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonFab,
  IonFabButton,
  modalController,
  loadingController
} from "@ionic/vue";
import { pencil, paw, chevronDown, ellipsisHorizontal } from "ionicons/icons";
import IconPen from "@/components/icons/IconPen";

/* モーダル */
// import PetProfileModal from "@/components/PetProfileModal";
import RecordWeightModal from "@/components/RecordWeightModal";

/* サービス */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { UserService } from "@/services/user.js";
import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

/* amChart */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";

/* ユーティリティ */

export default defineComponent({
  name: "History",
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonFab,
    IonFabButton,
    IconPen
  },
  setup() {
    return {
      pencil,
      paw,
      chevronDown,
      ellipsisHorizontal
    };
  },
  data: function() {
    return {
      userService: new UserService(firebase),
      // dbから返ってきたペットたちの情報を入れるための配列
      measurementLogs: [],
      // completedgetMeasurementLogs: false,
      // clickされたPetsのindex番号を入るための箱。（番号が一致したindexに.activeを付与する。）
      clickedOptionMenuNum: null,
      displayBackDrop: false,
      downFabZindx: false
    };
  },
  watch: {
    // この関数は selectedPetId が変わるごとに実行されます。
    selectedPetId: function() {
      this.measurementLogs = this.getMeasurementLogs(this.selectedPetId);
      this.getChart();
    },
    reloadMeasurementLogFlag: function() {
      if (this.reloadMeasurementLogFlag === true) {
        this.reloadMeasurementLog(this.selectedPetId);
        this.getChart();
      }
    }
  },
  async mounted() {
    await this.getMeasurementLogs(this.selectedPetId);
    // this.completedgetMeasurementLogs = true;

    /* amCharts */
    // this.getChart();
  },
  beforeUnmount() {
    /* amCharts */
    if (this.chart) {
      this.chart.dispose();
    }
  },
  async created() {
    this.dayjs = dayjs;
    if (this.selectedPetId === "") {
      await this.$router.push({ path: "pets" });
      await this.$router.go({
        path: this.$router.currentRoute.path,
        force: true
      });
    }
    // 通信時のローディングの表示について
    const notify = await loadingController.create({
      message: "読込中"
    });
    // 実際にここでloadingを表示
    notify.present();
    // ページが読み込まれたときにペットの情報をとってくる
    // 処理が終わったらloadingを消す
    notify.dismiss();
  },
  computed: {
    selectedPetProfile: function() {
      return this.$store.state.selectedPetProfile;
    },
    selectedPetId: function() {
      return this.$store.state.selectedPetId;
    },
    reloadMeasurementLogFlag: function() {
      return this.$store.state.reloadMeasurementLogFlag;
    },
    noMeasurementLog: function() {
      if (this.measurementLogs.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async getChart() {
      /* amChartsここから */
      let chart = await am4core.create(this.$refs.chartdiv, am4charts.XYChart);

      chart.paddingRight = 20;

      let data = [];

      let logLength = await this.measurementLogs.length;
      for (let i = 0; i < logLength; i++) {
        let tempDate = dayjs
          .unix(this.measurementLogs[i].log.date.seconds)
          .format("YYYY-MM-DD");

        data.push({
          date: new Date(tempDate),
          name: "name" + i,
          value: this.measurementLogs[i].log.weight
        });
      }
      chart.data = data;

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      // X軸の色（縦線）
      dateAxis.renderer.grid.template.stroke = "#f26600";

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
      // Y軸の色（横線）
      valueAxis.renderer.grid.template.stroke = "#f26600";

      // ズームアウトボタンの色
      chart.zoomOutButton.background.defaultState.properties.fill = am4core.color(
        "#f8a505"
      );
      chart.zoomOutButton.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("#f8a505").brighten(-0.1);
      chart.zoomOutButton.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("#f8a505");
      // ズームアウトボタンの色ここまで

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";

      series.tooltipText = "{valueY.value}{unit}";
      series.strokeWidth = 2;
      series.stroke = am4core.color("#f8a505");

      series.fillOpacity = 0.2;
      series.fill = am4core.color("#f8a505");
      var fillModifier = new am4core.LinearGradientModifier();
      fillModifier.opacities = [1, 0];
      fillModifier.offsets = [0, 1];
      fillModifier.gradient.rotation = 90;
      series.segments.template.fillModifier = fillModifier;

      chart.cursor = new am4charts.XYCursor();

      // let scrollbarX = new am4charts.XYChartScrollbar();
      // scrollbarX.series.push(series);
      // chart.scrollbarX = scrollbarX;

      chart.scrollbarX = new am4core.Scrollbar();

      // var bullet = series.bullets.push(new am4charts.CircleBullet());
      // bullet.fill = am4core.color("white");
      // bullet.strokeWidth = 3;

      /* 日本語化 */
      chart.dateFormatter.language = new am4core.Language(); //標準の翻訳設定
      chart.dateFormatter.language.locale = am4lang_ja_JP; //標準の翻訳設定
      chart.language.locale["_date_day"] = "M/dd"; // 独自ルールで上書き
      chart.language.locale["_date_year"] = "yyyy"; // 独自ルールで上書き

      this.chart = chart;
      /* amChartsここまで */
    },
    async getMeasurementLogs(selectedPetId) {
      let gotLog = await this.userService.getMeasurementLogs(selectedPetId);
      gotLog = await gotLog.sort(function(a, b) {
        if (a.log.date > b.log.date) return -1;
        if (a.log.date < b.log.date) return 1;
        return 0;
      });
      this.measurementLogs = await gotLog;
      this.getChart();
      // this.completedgetMeasurementLogs = true;
      // return this.measurementLogs;
    },
    formatTimestamp(date) {
      return dayjs(date).format("YYYY/MM/DD");
    },
    openOptionMenu(event, index) {
      if (this.clickedOptionMenuNum !== index) {
        // オプションメニューを表示する
        this.displayBackDrop = true;
        this.clickedOptionMenuNum = index;
        this.downFabZindx = true;
      } else {
        // 既に表示済みの時は消す
        this.displayBackDrop = false;
        this.clickedOptionMenuNum = null;
        this.downFabZindx = false;
      }
    },
    closeOptionMenu() {
      this.displayBackDrop = false;
      this.clickedOptionMenuNum = null;
      this.downFabZindx = false;
    },
    async confirmDeleteLog(index) {
      this.closeOptionMenu();
      this.$store.commit("setDeleteLogKey", this.measurementLogs[index].key);
      this.$store.commit(
        "setDeleteLogDate",
        this.measurementLogs[index].log.date
      );
      this.$store.commit(
        "setDeleteLogWeight",
        this.measurementLogs[index].log.weight
      );
      this.$store.commit(
        "setDeleteLogUnit",
        this.measurementLogs[index].log.unit
      );
      this.$store.commit("setDisplayConfirmDeleteLogModal", true);
    },
    async reloadMeasurementLog(selectedPetId) {
      await this.getMeasurementLogs(selectedPetId);
      await this.$store.commit("setReloadMeasurementLogFlag", false);
    },
    async openRecordWeightModal(addOrEdit, index) {
      // サブメニュー「編集」消す
      this.displayBackDrop = false;
      this.clickedOptionMenuNum = null;
      this.downFabZindx = false;

      let modal = null;

      switch (addOrEdit) {
        case "add": // indexはnull
          modal = await modalController.create({
            component: RecordWeightModal,
            cssClass: "record-pet-modal",
            componentProps: {
              logKeyEdit: null,
              dateEdit: null,
              weightEdit: null,
              memoEdit: null
            }
          });
          break;
        // それ以外：つまりEdit（update）のとき
        default:
          modal = await modalController.create({
            component: RecordWeightModal,
            cssClass: "record-pet-modal",
            componentProps: {
              logKeyEdit: this.measurementLogs[index].key,
              dateEdit: this.measurementLogs[index].log.date,
              weightEdit: this.measurementLogs[index].log.weight,
              memoEdit: this.measurementLogs[index].log.memo
            }
          });
          break;
      }
      return modal.present();
    }
    // async openPetProfileModal() {
    //   this.displayBackDrop = false;
    //   this.clickedOptionMenuNum = null;
    //   this.downFabZindx = false;

    //   let modal = await modalController.create({
    //     component: PetProfileModal,
    //     cssClass: "pet-profile-modal",
    //     componentProps: {
    //       petNameEdit: this.selectedPetProfile.petName,
    //       petTypeEdit: this.selectedPetProfile.petType,
    //       petWeightUnitEdit: this.selectedPetProfile.petWeightUnit,
    //       petWelcomeEdit: this.selectedPetProfile.petWelcome,
    //       petBirthEdit: this.selectedPetProfile.petBirth,
    //       petDeathEdit: this.selectedPetProfile.petDeath,
    //       petAvatarURLEdit: this.selectedPetProfile.petAvatarURL,
    //       petAvatarFileName: this.selectedPetProfile.petAvatarFileName,
    //     },
    //   });
    //   return modal.present();
    // },
  }
});
</script>

<style lang="scss" scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.name {
  font-size: 50%;
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10%;
}
.notifications {
  padding: 4px;
  border-top: 1px solid;
}
.page {
  background: #0000ff;
}
.pet_img {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5px;
  margin-right: 0.75rem;
}
.pet_img ion-icon {
  color: #ffffff;
  width: 24px;
  height: 24px;
}
.content-wrap {
  padding: 1rem;
}
.pet_info-wrap {
  width: calc(100% + 2rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 1.25rem;
  margin: -1rem -1rem 0;
  font-size: 1rem;
  position: relative;
  .icon {
    margin: 8px 8px 8px auto;
  }
}
.each-pet_info-text__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .pet-type {
    font-size: 0.75rem;
    color: rgba(37, 20, 4, 0.6);
    font-weight: 600;
    margin-top: 0.3rem;
  }
  .pet-name {
    font-size: 1.125rem;
    color: rgba(37, 20, 4, 0.87);
    font-weight: 600;
  }
  .pet-type + .pet-name {
    margin-top: 0.2rem;
  }
}
.select_pet_button {
  width: 2.5rem;
  background: none;
  color: rgba(37, 20, 4, 0.6);
  margin-left: auto;
}
.icon-pen {
  position: relative;
  top: -1px;
  right: -2px;
}
.guide-record-button {
  position: fixed;
  background: rgba(37, 20, 4, 0.68);
  color: #fff;
  padding: 0.5rem 0.8rem;
  /* bottom: calc(1.2rem + 8px); */
  bottom: 6rem;
  right: 1rem;
  border-radius: 4px;
}
.guide-record-button-arrow {
  position: fixed;
  bottom: 5rem;
  right: 2.5rem;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 8px 0 8px;
  border-color: rgba(37, 20, 4, 0.68) transparent transparent transparent;
}
.each-log {
  background: #fff;
  border-radius: 16px;
  padding: 20px 16px;
  margin-bottom: 16px;
  position: relative;
  .each-log-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .date {
      font-size: 1rem;
      font-weight: 400;
      color: rgba(37, 20, 4, 0.6);
    }
    .weight {
      display: flex;
      flex-direction: row;
      font-size: 1.25rem;
      font-weight: 700;
      color: rgba(37, 20, 4, 0.87);
      margin-left: auto;
      margin-right: 0.25rem;
    }
  }
  .memo {
    margin-top: 1rem;
    color: rgba(37, 20, 4, 0.6);
  }
}
/* オプションメニュー */
.option_menu_button {
  width: 2.5rem;
  background: none;
  color: rgba(0, 0, 0, 0.38);
}
.option_menu_list {
  &.active {
    display: block;
  }
  display: none;
  position: absolute;
  right: 0.5rem;
  top: 65%;
  z-index: 1100;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  .option_menu_list_item {
    padding: 0.75rem 1rem;
    color: rgba(37, 20, 4, 0.87);
    &:not(:last-child) {
      border-bottom: solid 1px rgba(0, 0, 0, 0.08);
    }
  }
}
.backdrop {
  display: none;
  &.display {
    display: block;
  }
}
.down-fab-z-index {
  z-index: -1;
}
/* amChart */
.amChart-wrap {
  margin: -16px -8px 24px -16px;
  margin: -1rem -0.5rem 1.5rem -1rem;
  .nodata {
    display: none;
  }
}
.amChart {
  width: 100%;
  height: 280px;
  &.nodata {
    display: none;
  }
}
</style>

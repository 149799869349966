<template>
  <ion-page class="page">
    <ion-content :fullscreen="true">
      <section class="page-wrapper">
        <ul class="menu menu--bottom">
          <li class="menu__list-item">
            <a @click="openLogoutAlert()">ログアウト</a>
          </li>
          <li class="menu__list-item divide">
            <a @click="goDeleteAccountPage()">アカウント削除（退会）</a>
          </li>
          <li class="menu__list-item">
            <a @click="openAboutThisApp()">このアプリについて</a>
          </li>
          <li class="menu__list-item">
            <a @click="openTermsOfUseModal()">利用規約</a>
          </li>
          <li class="menu__list-item">
            <a @click="openPrivacyPolicyModal()">プライバシーポリシー</a>
          </li>
          <li class="menu__list-item">
            <a @click="openSpecifiedCommercialTransactionsActModal()"
              >特定商取引法に基づく表示</a
            >
          </li>
          <li class="menu__list-item">
            <a @click="openLicense()">アプリ制作に伴うライセンス情報</a>
          </li>
        </ul>
      </section>
      <!-- 猫のイラスト -->
      <p class="cat_pink_comment">ダウンロード<br />ありがとにゃ〜</p>
      <CatPink class="cat_pink" />
      <!-- computed: fromDeleteAccountPageReloadPage を計算させるために配置しているdiv -->
      <div
        v-if="fromDeleteAccountPageReloadPage"
        class="reload-flag-data"
      ></div>
    </ion-content>
  </ion-page>
</template>

<script>
/* Vue With Ionic */
import { defineComponent } from "vue";
import {
  IonContent,
  IonPage,
  modalController,
  alertController,
} from "@ionic/vue";
import { paw, analyticsOutline, menu } from "ionicons/icons";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import aboutThisApp from "./about-this-app";
import TermsOfUseModal from "@/components/TermsOfUseModal";
import PrivacyPolicyModal from "@/components/PrivacyPolicyModal";
import SpecifiedCommercialTransactionsActModal from "@/components/SpecifiedCommercialTransactionsActModal";
import license from "./license";
import { UserService } from "@/services/user.js";

/* コンポーネント */
import CatPink from "@/../public/assets/images/CatPink";

/* ユーティリティ */
export default defineComponent({
  name: "Menu",
  components: {
    IonContent,
    IonPage,
    CatPink,
  },
  /**
   * props: paramsFromDeleteAccountPage
   * true ... delete-accountページから戻ってきた時
   * false ... それ以外
   * trueの時はtabを表示するためにページのリロードを行う
   */
  props: ["paramsFromDeleteAccountPage"],
  data: function() {
    return {
      userService: new UserService(firebase),
    };
  },
  setup() {
    const beforeTabChange = () => {
      // do something before tab change
    };
    const afterTabChange = () => {
      // do something after tab change
    };
    return {
      paw,
      analyticsOutline,
      menu,
      beforeTabChange,
      afterTabChange,
    };
  },
  async created() {},
  computed: {
    /**
     * computed: fromDeleteAccountPage
     * props: paramsFromDeleteAccountPage の値を検知
     * true ... delete-accountページから戻ってきた時
     * false ... それ以外
     * trueの時はtabを表示するためにページのリロードを行う
     */
    fromDeleteAccountPage: function() {
      return this.paramsFromDeleteAccountPage;
    },
    /**
     * computed: fromDeleteAccountPageReloadPage
     * delete-accountページから戻ってきた時ページのリロードを行う
     */
    fromDeleteAccountPageReloadPage: function() {
      if (this.fromDeleteAccountPage) {
        this.$router.go({
          path: "/menu",
          force: true,
        });
      }
      return this.fromDeleteAccountPage;
    },
  },
  methods: {
    /**
     * ログアウト確認アラート
     */
    async openLogoutAlert() {
      const alert = await alertController.create({
        cssClass: "confirmation-modal",
        header: "ログアウトしますか？",
        buttons: [
          {
            text: "キャンセル",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "ログアウト",
            handler: () => {
              this.doLogout();
            },
          },
        ],
      });
      return alert.present();
    },
    /**
     * ログアウト処理
     */
    doLogout() {
      firebase.auth().signOut();
      /**
       * ログアウトされている状態でページをリロードすると
       * routerに記載した情報を元にページ遷移する
       */
      location.reload();
    },
    /**
     * アカウント削除（退会）
     */
    goDeleteAccountPage() {
      this.$router.push("/delete-account");
    },
    /**
     * このアプリについて
     */
    async openAboutThisApp() {
      let modal = await modalController.create({
        component: aboutThisApp,
        cssClass: "about-this-app-modal",
      });
      return modal.present();
    },
    /**
     * 利用規約
     */
    async openTermsOfUseModal() {
      let modal = await modalController.create({
        component: TermsOfUseModal,
        cssClass: "terms-of-use-modal",
      });
      return modal.present();
    },
    /**
     * プライバシーポリシー
     */
    async openPrivacyPolicyModal() {
      let modal = await modalController.create({
        component: PrivacyPolicyModal,
        cssClass: "privacy-policy-modal",
      });
      return modal.present();
    },
    /**
     * 特定商取引法に基づく表示
     */
    async openSpecifiedCommercialTransactionsActModal() {
      let modal = await modalController.create({
        component: SpecifiedCommercialTransactionsActModal,
        cssClass: "specified-commercial-transactions-act-modal",
      });
      return modal.present();
    },
    /**
     * ライセンス
     */
    async openLicense() {
      let modal = await modalController.create({
        component: license,
        cssClass: "license-modal",
      });
      return modal.present();
    },
  },
});
</script>

<style lang="scss" scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.name {
  font-size: 50%;
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10%;
}
.notifications {
  padding: 4px;
  border-top: 1px solid;
}
.page-wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 4rem);
}
/* menu */
.menu {
  margin: 0;
  padding: 0;
  &__list-item {
    list-style-type: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    font-weight: bold;
    a {
      display: flex;
      padding: 1rem 0;
      color: rgba(37, 20, 4, 0.87);
    }
  }
  &--bottom {
    .menu__list-item {
      list-style-type: none;
      border-bottom: none;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      font-size: 0.75rem;
      font-weight: normal;
      &:first-of-type {
        border-top: none;
      }
      &.divide {
        margin-bottom: 4rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
  }
}
/* 猫の画像 */
.cat_pink {
  width: 80px;
  height: auto;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
.cat_pink_comment {
  font-size: 0.7rem;
  text-align: center;
  height: auto;
  position: fixed;
  bottom: 5.8rem;
  right: 1rem;
}
/**
 * computed: fromDeleteAccountPageReloadPage を計算させるために配置しているdivを消す
 */
.reload-flag-data {
  opacity: 0;
}
</style>

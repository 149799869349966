import { createApp } from "vue";
import App from "./app";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Firebase Initialize */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

/**
 * 本番環境
 */
const config = {
  apiKey: "AIzaSyAt0PVtjsEn1gvY0O7Q82KMcUb8aWafzCI",
  /**
   * 元々は
   * authDomain: "pet-weight-chart.firebaseapp.com",
   * 独自ドメインにしたので書き換え
   */
  authDomain: "pet-weight-chart.com",
  projectId: "pet-weight-chart",
  storageBucket: "pet-weight-chart.appspot.com",
  messagingSenderId: "620712404274",
  appId: "1:620712404274:web:1db8a65b3c96a8209d632b",
  measurementId: "G-45QC82MYVY"
};
firebase.initializeApp(config);

/**
 * 開発環境
 */
// const configDev = {
//   apiKey: "AIzaSyDABknwcecuxRZH37M2iUHm-BiydZ8QcfI",
//   authDomain: "dev-pet-weight-chart.firebaseapp.com",
//   projectId: "dev-pet-weight-chart",
//   storageBucket: "dev-pet-weight-chart.appspot.com",
//   messagingSenderId: "44320945874",
//   appId: "1:44320945874:web:f66e28442a7eb91b715f0b",
//   measurementId: "G-NMCK17S8QH"
// };
// firebase.initializeApp(configDev);

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* vuex */
import store from "./store";

const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router);

router.isReady().then(() => {
  app.mount("#app");
});

<template>
  <ion-page>
    <ion-content>
      <div class="wrap-content">
        <div class="service-name">ペットの体重<br />グラフにす〜る<br /></div>
        <p class="service-name--sub">Pet Weight Chart</p>
        <Cats class="app-img" />
        <p class="agreement">
          <a @click="openTermsOfUseModal()">利用規約</a>と<a
            @click="openPrivacyPolicyModal()"
            >プライバシーポリシー</a
          >に<br />同意して
        </p>
        <button class="googleAuth-btn" @click="googleAuth">
          <GoogleLogo class="google-icon" />
          Googleアカウントで<br class="only-se" />ログイン
        </button>
        <div class="description">
          ご利用になるには
          <br />googleアカウントが必要です。
        </div>
        <p class="agreement">
          <a @click="openSpecifiedCommercialTransactionsActModal()"
            >特定商取引法に基づく表示</a
          >
        </p>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { UserService } from "@/services/user.js";
import { defineComponent } from "vue";
import {
  IonContent,
  IonPage,
  loadingController,
  modalController,
} from "@ionic/vue";

import Cats from "@/../public/assets/images/Cats";
import GoogleLogo from "@/../public/assets/images/GoogleLogo";

import UpdatePleaseModal from "@/components/UpdatePleaseModal";
import TermsOfUseModal from "@/components/TermsOfUseModal";
import PrivacyPolicyModal from "@/components/PrivacyPolicyModal";
import SpecifiedCommercialTransactionsActModal from "@/components/SpecifiedCommercialTransactionsActModal";

export default defineComponent({
  name: "Welcome",
  components: {
    IonContent,
    IonPage,
    Cats,
    GoogleLogo,
  },
  data: function() {
    return {
      userService: new UserService(firebase),
    };
  },
  async created() {
    // 最新versionが存在する時に更新を促すダイアログを表示する
    const needOpenUpdateModal = await this.userService.compareVersions();
    if (needOpenUpdateModal === "openUpdateModal") {
      this.openUpdate();
    }
  },
  methods: {
    /*
     * バージョンの更新（アップデートを促す）
     * アップデートしてくださいモーダルを開く
     */
    async openUpdate() {
      let modal = await modalController.create({
        component: UpdatePleaseModal,
        cssClass: "update-please-modal",
      });
      return modal.present();
    },
    /**
     * 利用規約モーダルを開く
     */
    async openTermsOfUseModal() {
      let modal = await modalController.create({
        component: TermsOfUseModal,
        cssClass: "terms-of-use-modal",
      });
      return modal.present();
    },
    /*
     * プライバシーポリシーモーダルを開く
     */
    async openPrivacyPolicyModal() {
      let modal = await modalController.create({
        component: PrivacyPolicyModal,
        cssClass: "privacy-policy-modal",
      });
      return modal.present();
    },
    /**
     * 特定商取引法に基づく表示モーダルを開く
     */
    async openSpecifiedCommercialTransactionsActModal() {
      let modal = await modalController.create({
        component: SpecifiedCommercialTransactionsActModal,
        cssClass: "specified-commercial-transactions-act-modal",
      });
      return modal.present();
    },
    /**
     * googleログイン認証：
     * deploy後、認証が失敗していたのは、「独自ドメイン」をログイン時に許可する設定をしていなかったから。
     * 以下の認証のソースコード自体には問題なし。
     * 対策１）
     * https://console.firebase.google.com/project/pet-weight-chart/authentication/providers
     * から、「承認済みドメイン」にも『pet-weight-chart.com』を追加した。
     * 対策２）
     * main.js 内の configを authDomain: "pet-weight-chart.com" に変更
     * 対策３）
     * https://console.cloud.google.com/apis/credentials?project=pet-weight-chart
     * から、「認証情報 > OAuth 2.0 クライアント ID」内で、
     * 「Web client  (auto created by Google Service)」の
     * 承認済みのリダイレクト URIに『https://pet-weight-chart.com/__/auth/handler』を、追加した。
     * ついでに「承認済みの JavaScript 生成元」にも独自ドメインを追加した。
     * また、「0Auth 同意画面 > アプリ登録の編集」画面から、「承認済みドメイン」に独自ドメインを追加した。
     */
    async googleAuth() {
      const loading = await loadingController.create({
        message: "Please wait...",
      });
      await loading.present();
      /**
       * サインインの方法をpopupからredirectにする必要があり、変更した。
       * Error: Unable to establish a connection with the popup. It may have been blocked by the browser.
       * SPでログイン用のpopupが表示されず、ログインできなかった。
       */
      const provider = await new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithRedirect(provider);
    },
  },
});
</script>

<style lang="scss" scoped>
.wrap-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.service-name {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: rgba(37, 20, 4, 0.87);
}
.service-name--sub {
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
.description {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 1rem;
  color: rgba(37, 20, 4, 0.6);
}
.googleAuth-btn {
  display: block;
  margin: 0 auto;
  padding: 12px 24px 10px;
  width: 75%;
  max-width: 20rem;
  font-size: 1rem;
  line-height: 1.4;
  text-align: center;
  font-weight: bold;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(37, 20, 4, 0.87);
  border-radius: 16px;
}
.app-img {
  display: block;
  width: 70%;
  max-width: 230px;
  margin: 0 auto 16px;
  height: auto;
}
.only-se {
  display: none;
}
@media screen and (max-width: 375px) {
  .only-se {
    display: block;
  }
}
.agreement {
  padding: 1rem 1rem;
  text-align: center;
  margin-top: 0.6rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  a {
    font-weight: 600;
    color: #cf733d;
  }
}
.google-icon {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
}
</style>

<template>
  <div class="confirm-wrap">
    <ion-card>
      <ion-card-header>
        <div v-if="deletePetAvatarUrl === ''" class="pet_img">
          <ion-icon :icon="paw"></ion-icon>
        </div>
        <img v-else :src="deletePetAvatarUrl" class="pet_img" />
        <ion-card-title>{{ deletePetName }}</ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <p>
          本当に削除しますか？
          <br />（履歴・写真が全て消えます）
        </p>
        <div class="buttons">
          <ion-buttons>
            <ion-button @click="cancel()" class="cancel-button"
              >キャンセル</ion-button
            >
            <ion-button color="danger" @click="deletePet(deletePetKey)"
              >削除</ion-button
            >
          </ion-buttons>
        </div>
      </ion-card-content>
    </ion-card>
    <BackDrop class="backdrop" />
  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { paw } from "ionicons/icons";
import BackDrop from "@/components/BackDrop";

/* サービス */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { UserService } from "@/services/user.js";

export default defineComponent({
  setup() {
    return {
      paw,
    };
  },
  data: function() {
    return {
      userService: new UserService(firebase),
    };
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonButtons,
    IonButton,
    BackDrop,
    IonIcon,
  },
  computed: {
    deletePetKey: function() {
      return this.$store.state.deletePetKey;
    },
    deletePetName: function() {
      return this.$store.state.deletePetName;
    },
    deletePetAvatarFileName: function() {
      return this.$store.state.deletePetAvatarFileName;
    },
    deletePetAvatarUrl: function() {
      return this.$store.state.deletePetAvatarUrl;
    },
    selectedPetId: function() {
      return this.$store.state.selectedPetId;
    },
  },
  methods: {
    async cancel() {
      {
        await this.$store.commit("setDeletePetKey", "");
        await this.$store.commit("setDeletePetName", "");
        await this.$store.commit("setDeletePetAvatarUrl", "");
        await this.$store.commit("setDisplayConfirmDeletePetModal", false);
      }
    },
    async deletePet(deletePetKey) {
      /**
       * ペットの削除時に、
       * userのid名のドキュメントが存在しなければ
       * users/{userId}/ を作って、そこに
       * userId、createdAt、updatedAt を作る。
       * 既に存在していれば、updatedAt を更新する
       * 1匹目の登録時にドキュメントを作成するので、
       * 通常は既に存在するはず。
       */
      await this.userService.addOrUpdateUserDocument();
      /*
       * StrageからAvatar画像「avatar.xxx」消す処理
       */
      let completeDeletePetImages = false;
      if (this.deletePetAvatarFileName === "") {
        completeDeletePetImages = true;
      } else if (this.deletePetAvatarFileName !== "") {
        completeDeletePetImages = await this.userService.deletePetProfileAvatar(
          deletePetKey,
          this.deletePetAvatarFileName
        );
      }

      /*
       * Cloud Firestoreの中の該当するpetのデータを消し、エラーなく削除できたらtrueを返す
       */
      let completeDeletePet = false;
      completeDeletePet = await this.userService.deletePet(deletePetKey);

      /*
       * 削除するpetIdについての、画像の削除と、ペット情報の削除両方が完了したらページをリロードする
       */
      if (completeDeletePetImages === true && completeDeletePet === true) {
        await this.$store.commit("setDeletePetKey", "");
        await this.$store.commit("setDeletePetName", "");
        await this.$store.commit("setDeletePetAvatarFileName", "");
        await this.$store.commit("setDeletePetAvatarUrl", "");
        await this.$store.commit("setDisplayConfirmDeletePetModal", false);
        /*
         * 成功したら、リロードする
         */
        this.$router.go({
          path: this.$router.currentRoute.path,
          force: true,
        });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.confirm-wrap {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
}
ion-card {
  background: #fff;
  z-index: 1000;
  min-width: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.backdrop {
  z-index: 100;
}
ion-card-header {
  text-align: center;
}
ion-card-title {
  font-size: 1.25rem;
}
ion-card-content {
  width: 100%;
}
ion-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.buttons {
  display: flex;
  margin-top: 1.5rem;
  width: 100%;
}
.cancel-button {
  padding-left: 0;
  margin-right: 2rem;
}
.pet_img {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-right: auto;
  margin-left: auto;
}
.pet_img ion-icon {
  color: #ffffff;
  width: 24px;
  height: 24px;
}
p {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 0.5rem;
}
</style>
